import clsx from "clsx";
import React from "react";
import {useTranslation} from "react-i18next";
import {FieldErrors} from "react-hook-form/dist/types/errors";
import T from "components/T";
import {ExclamationCircleIcon} from "@heroicons/react/24/outline";

interface BookingLoginReservationDetailInputProps {
    placeholder?: string;
    value?: string;
    disabled?: boolean;
    name: string;
    register?: any;
    className?: string;
    classNameInput?: string;
    type?: string;
    errors?: FieldErrors;
    label?: string;
    required?: boolean;
    style?: "normal" | "inline";
}

const BookingLoginReservationDetailInput = (props: BookingLoginReservationDetailInputProps) => {
    const {t} = useTranslation();
    return (
        <div className={clsx("BookingLoginReservationDetailInput w-full", props?.style === "inline" && "sm:flex sm:flex-row sm:space-x-6 sm:items-center sm:flex-wrap", props.className)}>
            {props.label &&
                <label
                    className={clsx(
                        "font-medium mb-1 flex",
                        props?.style === "inline" && "sm:!w-1/3 sm:justify-end sm:flex-1",
                        props.errors?.[props.name] && "text-red-500"
                    )}
                    htmlFor={"label-" + props.name}
                >
                    <span>{t(props.label)}</span>
                    {props.required &&
                        <em className={"text-red-500"}>*</em>
                    }
                </label>
            }
            <input
                {...props?.register && {...props?.register(props.name)}}
                className={clsx(
                    "w-full px-3 py-1.5 rounded-md border-gray-200 border",
                    props?.style === "inline" && "sm:!w-2/3",
                    props.errors?.[props.name] && "border-red-500",
                    props.disabled && "bg-gray-100 text-gray-600",
                    props.classNameInput
                )}
                disabled={props.disabled}
                id={"label-" + props.name}
                name={props.name}
                placeholder={props.placeholder}
                type={props?.type ? props.type : "text"}
            />
            {props.errors?.[props.name]?.message && (
                <div className={clsx(
                    "text-red-500 text-sm mt-1 flex flex-row items-center",
                    props?.style === "inline" && "sm:w-full sm:!ml-0"
                )}>
                    {props?.style === "inline" && <div className={"hidden sm:flex sm:w-1/3"}></div>}
                    <div className={clsx("shrink-0 flex flex-row items-center", props?.style === "inline" ? "sm:w-2/3" : "w-full")}>
                        <ExclamationCircleIcon className={"min-w-[18px] min-h-[18px] w-[18px] h-[18px] mr-0.5"} />
                        <T>{props.errors?.[props.name]?.message}</T>
                    </div>
                </div>
            )}
        </div>
    );
};

export default BookingLoginReservationDetailInput;