import React from "react";
import {PackageItem} from "types/entities/PackageItem";
import clsx from "clsx";
import {PackageUpsellItemLabels} from "pages/Front/Booking/components/PackageUpsell/components/PackageUpsellItemLabels";
import {PackageUpsellItemPrice} from "pages/Front/Booking/components/PackageUpsell/components/PackageUpsellItemPrice";
import ButtonSubmit from "pages/Front/Booking/components/ButtonSubmit";
import {currencyNumberFormat, getSelectedUpsell} from "utils/utility";
import {useTypedSelector} from "helpers/reducers";
import {useTranslation} from "react-i18next";
import {ServerImage} from "components/ServerImage";

interface PackageUpsellItemSellerContainerProps {
    setSelectedPackageUpsell: () => void;
    className: string;
    packageItem: PackageItem;
    inputs: React.ReactNode | React.ReactNode[];
    showRemoveButton: boolean;
    contained?: PackageItem | boolean;
    callToAction?: boolean;
    style: any;
    children?: React.ReactNode | React.ReactNode[];
    isGroupedProduct?: boolean;
    isLoading?: boolean;
    isNewLayout?: boolean;
}

const PackageUpsellItemSellerContainer = (props: PackageUpsellItemSellerContainerProps) => {
    const bookingProcess = useTypedSelector(state => state.bookingState.bookingProcess);
    const selectedUpsell = getSelectedUpsell(bookingProcess.upsell, props.packageItem);
    const {t} = useTranslation();

    return (
        <div className={clsx(props.className, "PackageUpsellItemSellerContainer relative flex flex-col")}>
            <li
                className={clsx(
                    "rounded-lg shadow",
                    "flex flex-col sm:flex-row sm:flex-wrap items-stretch content-stretch text-center bg-white overflow-hidden z-20"
                )}
                style={props.style}
            >
                <div className={"grow flex flex-col items-stretch sm:max-w-[128px] overflow-hidden"}>
                    {props.packageItem.image && props.packageItem.image !== "" && (
                        <div className={"packageItemImage flex h-full aspect-auto w-full grow"}>
                            <ServerImage
                                alt={"Package Item Image"}
                                className={"w-full max-h-auto object-cover object-center"}
                                src={props.packageItem.image}
                            />
                        </div>
                    )}
                </div>

                <div className={"flex flex-col flex-1 text-left pl-2 pr-2 py-2 gap-y-1"}>
                    <PackageUpsellItemLabels className={"!p-0"} isGroupedProduct={props.isGroupedProduct} isSeller={true} packageItem={props.packageItem} type="both" />
                    <PackageUpsellItemLabels className={"!p-0"} packageItem={props.packageItem} type="upper" />

                    <div className={"flex flex-row justify-between items-end"}>
                        <div className={"flex items-start border p-2 rounded-md bg-gray-50 w-auto max-w-[160px]"}>
                            {props.isGroupedProduct ? (
                                <div className={"font-light text-gray-800 text-xs sm:text-sm"}>{t("Included in Package")}</div>
                            ) : (
                                <PackageUpsellItemPrice isSeller={true} packageItem={props.packageItem} />
                            )}
                        </div>
                        <div>
                            {props.inputs}
                        </div>
                    </div>

                </div>

                <div className={"w-[180px] flex flex-col justify-end items-center py-2 pr-2 gap-y-1"}>
                    {!props.isGroupedProduct && (
                        <>
                            {selectedUpsell && (
                                <div className={"flex flex-col"}>
                                    <span className={"text-sm text-gray-600"}>{t("Total price")}:</span>
                                    <span className={"font-bold leading-none"}>
                                        {currencyNumberFormat(selectedUpsell.count * props.packageItem.price)}
                                    </span>
                                </div>
                            )}
                            <ButtonSubmit
                                buttonType={props.showRemoveButton ? "selected" : "arbo-orange"}
                                callToAction={props.callToAction}
                                className={"w-full rounded-md !leading-none !p-1.5 sm:!py-[7px] sm:!px-1.5 !grow-0"}
                                disabled={props.contained && !props.showRemoveButton}
                                icon={props.showRemoveButton ? "check" : "plus"}
                                id={"ButtonSelectUpsell"}
                                label={props.showRemoveButton ? t("Selected") : t("Add")}
                                onClick={props.setSelectedPackageUpsell}
                            />
                        </>
                    )}
                </div>

                <PackageUpsellItemLabels packageItem={props.packageItem} type="lower" />
                {props.children}
            </li>
        </div>
    );
};

export default PackageUpsellItemSellerContainer;