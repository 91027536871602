import React from "react";
import clsx from "clsx";

interface TableHeadProps {
    children: React.ReactNode | React.ReactNode[];
    className?: string;
    classNameInner?: string;
}

const TableHead = (props: TableHeadProps) => {
    return (
        // <thead className={clsx("TableHead font-medium border-t border-b-2 border-gray-200 bg-gray-50 text-gray-900 drop-shadow-sm", props.className)}>
        <thead className={clsx("TableHead font-medium border-b-2 border-gray-200 bg-gray-50 text-gray-900", props.className)}>
            <tr className={clsx(props.classNameInner)}>
                {props.children}
            </tr>
        </thead>
    );
};

export default TableHead;