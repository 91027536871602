import React from "react";
import clsx from "clsx";

interface TableHeadColumnProps {
    children: React.ReactNode | React.ReactNode[];
    className?: string;
    decoration?: boolean;
}

const TableHeadColumn = (props: TableHeadColumnProps) => {
    return (
        <th className={clsx("TableHeadColumn py-3 px-1.5 first:!pl-3 last:!pr-3 align-middle truncate", props.decoration && "border-r last:border-r-0", props.className)}>
            {props.children}
        </th>
    );
};

export default TableHeadColumn;