import React from "react";
import {RouteComponentProps, useHistory, withRouter} from "react-router-dom";
import {useTranslation} from "react-i18next";
import {useDispatch} from "useDispatch";
import {useTypedSelector} from "helpers/reducers";
import {Reservation} from "types/entities/Reservation";
import BookingLoginReservationButton
    from "pages/Front/Booking/BookingLogin/components/BookingLoginReservationDetail/components/BookingLoginReservationButton";
import BookingLoginReservationButtonInner
    from "pages/Front/Booking/BookingLogin/components/BookingLoginReservationDetail/components/BookingLoginReservationButtonInner";
import {BookingLoginReservationButtonSize} from "types/enums/BookingLoginReservationButtonSize";
import {BookingLoginReservationButtonStyle} from "types/enums/BookingLoginReservationButtonStyle";
import {ArrowUturnLeftIcon} from "@heroicons/react/24/solid";
import BookingLoginReservationDetailSummaryForm
    from "pages/Front/Booking/BookingLogin/components/BookingLoginReservationDetail/components/BookingLoginReservationDetailSummaryForm";

interface Params {
    reservation_id: string;
    cart_guid: string;
}

interface BookingLoginReservationDetailSummaryProps extends RouteComponentProps<Params> {
}

const BookingLoginReservationDetailSummary = (props: BookingLoginReservationDetailSummaryProps) => {
    const {t} = useTranslation();
    const dispatch = useDispatch();
    const history = useHistory();
    const reservation_id = +props.match.params.reservation_id;
    const cart_guid = props.match.params.cart_guid;
    const reservations = useTypedSelector(state => state.bookingLoginState.reservations);
    const reservation = reservations.find(reservation => reservation.id === reservation_id) as Reservation; // this is always a reservation

    return (
        <div className={"BookingLoginReservationDetailSummary w-full"}>

            <BookingLoginReservationDetailSummaryForm reservation={reservation} />

            <div className={"mt-6 w-full flex gap-4 flex-row justify-between items-center"}>
                <BookingLoginReservationButton type={"button"} onClick={() => history.push("/booking/login/reservations/detail/" + reservation_id + "/upsells/" + cart_guid)}>
                    <BookingLoginReservationButtonInner
                        size={BookingLoginReservationButtonSize.Normal}
                        style={BookingLoginReservationButtonStyle.Gray}
                    >
                        <ArrowUturnLeftIcon className={"flex-none w-[16px] h-[16px]"} />
                        <span className={"ml-2"}>{t("Back")}</span>
                    </BookingLoginReservationButtonInner>
                </BookingLoginReservationButton>
                {/*<BookingLoginReservationButton type={"button"} onClick={() => {}}>*/}
                {/*    <BookingLoginReservationButtonInner*/}
                {/*        size={BookingLoginReservationButtonSize.Normal}*/}
                {/*        style={BookingLoginReservationButtonStyle.Green}*/}
                {/*    >*/}
                {/*        <CreditCardIcon className={"flex-none w-[16px] h-[16px]"} />*/}
                {/*        <span className={"ml-2"}>{t("Buy")}</span>*/}
                {/*    </BookingLoginReservationButtonInner>*/}
                {/*</BookingLoginReservationButton>*/}
            </div>
        </div>
    );
};

export default withRouter(BookingLoginReservationDetailSummary);