export enum UpsellOptionType {
    Before = "before",
    During = "during",
    After = "after",
    NoOption = "none"
}

export interface UpsellOption {
    id: number;
    option: UpsellOptionType;
    label: string;
}
export const upsellOptions: UpsellOption[] = [
    {id: 1, option: UpsellOptionType.Before, label: "Before activity"},
    {id: 2, option: UpsellOptionType.During, label: "During activity"},
    {id: 3, option: UpsellOptionType.After, label: "After activity"},
];
