import React from "react";
import {useTranslation} from "react-i18next";
import {Package} from "types/entities/Package";
import {ClockIcon} from "@heroicons/react/24/outline";

interface PackageProps {
    package: Package;
    is_minimal?: boolean;
}

const PackageMinDaysFromNow = (props: PackageProps) => {
    const {t} = useTranslation();
    return (
        <div className={"text-xs text-gray-900 flex items-center justify-center"}>
            {props.package.extra.minDaysFromNow && props.package.extra.minDaysFromNow > 0 ? (
                <>
                    <dt className="sr-only">{t("Drive time")}</dt>
                    {props.is_minimal ? (
                        <dd className={"first-letter:capitalize text-xs text-gray-800 break-words"}>
                            {t("reservation possible {{days}} days upfront", {days: props.package.extra.minDaysFromNow})}
                        </dd>
                    ) : (
                        <span className={"inline-flex items-center justify-center bg-gray-400 rounded-full px-2 py-1"}>
                            <ClockIcon className={"w-5 h-5 mr-2"} />
                            <dd className={"first-letter:capitalize"}>
                                {t("reservation possible {{days}} days upfront", {days: props.package.extra.minDaysFromNow})}
                            </dd>
                        </span>
                    )}
                </>
            ): ("")}
        </div>
    );
};

export default PackageMinDaysFromNow;
