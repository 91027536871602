import React, {useEffect, useState} from "react";
import {useDispatch} from "useDispatch";
import {useForm} from "react-hook-form";
import {useTranslation} from "react-i18next";
import {toast} from "react-toastify";
import {RouteComponentProps, withRouter} from "react-router-dom";
import SellerForgottenPasswordRecoverySuccess
    from "pages/Front/Booking/Seller/SellerForgottenPassword/SellerForgottenPasswordRecoverySuccess";
import SellerForgottenPasswordRecoveryAccessForm
    from "pages/Front/Booking/Seller/SellerForgottenPassword/SellerForgottenPasswordRecoveryAccessForm";
import SellerForgottenPasswordRecoveryAccessTokenRequested
    from "pages/Front/Booking/Seller/SellerForgottenPassword/SellerForgottenPasswordRecoveryAccessTokenRequested";
import SellerForgottenPasswordForgottenPasswordRecoveryForm
    from "pages/Front/Booking/Seller/SellerForgottenPassword/SellerForgottenPasswordForgottenPasswordRecoveryForm";
import SellerAction from "store/seller/SellerAction";
import * as yup from "yup";
import {yupResolver} from "@hookform/resolvers/yup";

interface SellerForgottenPasswordFormParams {
    access_token?: string;
    app_id?: string;
    m_clk_id?: string;
}

interface SellerForgottenPasswordFormProps extends RouteComponentProps<SellerForgottenPasswordFormParams> {
}

const SellerForgottenPasswordForm = (props: SellerForgottenPasswordFormProps) => {
    const {t} = useTranslation();
    const dispatch = useDispatch();
    const [isSubmitting, setIsSubmitting] = useState<boolean>(false);
    const REGEX_EMAIL = /^[a-zA-Z0-9._%+-]+@[a-zA-Z0-9.-]+\.[a-zA-Z]{2,}$/i;
    const EmailRecoveryPasswordSchema = yup.object().shape({
        email: yup
            .string()
            .required(t("Email is required field."))
            .test("email", t("Invalid email format"), value => {
                return REGEX_EMAIL.test(value);
            }),
    });
    const defaultValues = {
        email: "",
    };
    const {
        register,
        handleSubmit,
        formState: {errors},
    } = useForm({
        resolver: yupResolver<any>(EmailRecoveryPasswordSchema),
        defaultValues: defaultValues,
    });
    const [accessToken, setAccessToken] = useState<boolean>(false);
    const [accessTokenRequested, setAccessTokenRequested] = useState<boolean>(false);
    const [accessGranted, setAccessGranted] = useState<boolean>(false);
    const [accessEmailRequested, setAccessEmailRequested] = useState<string>("");
    const params = props.match.params;

    const onSubmitPasswordForgotten = data => {
        if (isSubmitting) {
            return;
        }
        setIsSubmitting(true);

        const app_url = window.location.hostname + ":" + window.location.port;
        dispatch(SellerAction.forgottenPassword(data.email, app_url)).then(r => {
            setAccessEmailRequested(data.email);
            setAccessTokenRequested(true);
            toast.success(t("Please check your email for access token"));
        }).finally(() => {
            setIsSubmitting(false);
        });
    };

    useEffect(() => {
        if (params.access_token && params.access_token.length === 32) {
            setAccessToken(true);
        }
        return () => {};
    }, [params]);

    return (
        <div className={"SellerForgottenPasswordForm"}>
            {accessGranted ? (
                <SellerForgottenPasswordRecoverySuccess />
            ) : (
                <div className={"flex"} id={"AccessPending"}>
                    {accessToken ? (
                        <SellerForgottenPasswordRecoveryAccessForm accessEmailRequested={accessEmailRequested} params={props.match.params} setAccessGranted={setAccessGranted} />
                    ) : (
                        <div className={"flex"} id={"RecoveryFormHolder"}>
                            {accessTokenRequested ? (
                                <SellerForgottenPasswordRecoveryAccessTokenRequested />
                            ) : (
                                <SellerForgottenPasswordForgottenPasswordRecoveryForm isSubmitting={isSubmitting} errors={errors} register={register} onSubmit={handleSubmit(onSubmitPasswordForgotten)} />
                            )}
                        </div>
                    )}
                </div>
            )}
        </div>
    );
};

export default withRouter(SellerForgottenPasswordForm);