import React from "react";
import {FieldErrors} from "react-hook-form/dist/types/errors";
import clsx from "clsx";
import T from "components/T";

interface CompanyFormCheckboxProps {
    errors: FieldErrors;
    register: any;
    name: string;
    className?: string;
    classNameLabel?: string;
    placeholder?: string;
    required?: boolean;
    disabled?: boolean;
    iconName?: string;
    children: React.ReactNode[] | React.ReactNode;
}

const CompanyFormCheckbox = (props: CompanyFormCheckboxProps) => {
    return (
        <div
            className={clsx(
                "CompanyFormCheckbox",
                "border rounded-md p-2",
                props.errors?.[props.name] ? "border-red-500 bg-red-50" : "border-transparent",
                props.className
            )}
        >
            <label
                className={clsx(
                    "flex flex-row",
                )}
                htmlFor={"input-" + props.name}
            >
                <input
                    {...props?.register(props.name)}
                    className={clsx(
                        "w-5 h-5 p-1 mr-3 mt-0.5 border rounded-md shadow-xs focus:outline-none active:ring-cta-800 active:ring-2 active:ring-offset-2 focus:border-cta-800 checked:bg-cta-800 sm:text-sm border-gray-800",
                        props.errors?.[props.name] ? "border-red-500 ring-1 ring-red-500" : "",
                        props.className
                    )}
                    disabled={props.disabled}
                    id={"input-" + props.name}
                    name={props.name}
                    // required={props.required}
                    type="checkbox"
                />
                <div className={clsx("flex flex-row justify-between w-full text-sm", props.classNameLabel)}>
                    <span className={"w-full"}>{props.children}</span>
                    {props.required &&
                        <em className={"text-red-500"}>*</em>
                    }
                </div>
            </label>
            {props.errors?.[props.name]?.message && (
                <div className={"text-red-500 text-sm ml-8 mt-1 flex flex-row"}>
                    <T>{props.errors?.[props.name]?.message}</T>
                </div>
            )}
        </div>
    );
};

export default CompanyFormCheckbox;