import React from "react";
import {ShieldExclamationIcon} from "@heroicons/react/24/solid";
import {useTranslation} from "react-i18next";
import {DEFAULT_HALL, DEFAULT_RESERVATION_ARRIVAL_MINUTES} from "helpers/config";
import {HallCode} from "types/enums/HallCode";
import {PackagesCartItem} from "types/entities/PackagesCartItem";
import { PackageUpsell } from "store/booking/BookingAction";

const ImportantArriveTime = (props: {selectedUpsells: PackagesCartItem[]|PackageUpsell[]}) => {
    const {t} = useTranslation();
    return (
        <div className={"ImportantArriveTime w-full flex flex-col sm:items-center"}>
            <div className={"p-2 flex flex-row items-center text-xs w-full"}>
                <ShieldExclamationIcon className={"w-5 h-5 mr-2 text-red-500"} />
                <div className={"flex flex-col items-start"}>
                    <strong>{t("Important!")}</strong>
                    <span>
                        {t("arrive before time single activity", {
                            minutes:
                                props.selectedUpsells.length > 0
                                    ? DEFAULT_RESERVATION_ARRIVAL_MINUTES + (DEFAULT_HALL === HallCode.Zwolle ? 10 : 0)
                                    : DEFAULT_RESERVATION_ARRIVAL_MINUTES,
                        })}
                    </span>
                </div>
            </div>
        </div>
    );
};

export default ImportantArriveTime;
