import React from "react";
import YearlyPackageInfo from "pages/Front/Booking/components/YearlyPackage/components/YearlyPackageInfo";
import {useTranslation} from "react-i18next";

interface Yearly6MonthsDescriptionProps {
}

const Yearly6MonthsDescription = (props: Yearly6MonthsDescriptionProps) => {
    const {t} = useTranslation();
    return (
        <YearlyPackageInfo
            headerLine={t("6 Months fun with no End")}
            articleName={t("Semi-Annual - ABO")}
            descriptionTitle={t("A half year admittance to ArboPark")}
            points={[
                t("Valid 6 months"),
                t("4 Hours Entry 'Yellow'"),
                t("1 Entry per day"),
                t("Valid every day on official opening hours (Midweek and Weekend)"),
                t("Karting not included. Available separately for discounted price CHF 9.95"),
            ]}
        />
    );
};

export default Yearly6MonthsDescription;