import React from "react";
import {ArrowUturnLeftIcon} from "@heroicons/react/24/solid";
import ButtonSubmit from "pages/Front/Booking/components/ButtonSubmit";
import clsx from "clsx";
import {useTranslation} from "react-i18next";

interface SellerFooterProps {
    nextStepDisabled?: boolean;
    onClickNext?: () => void;
    onClickBack?: () => void;
    children?: React.ReactNode | React.ReactNode[];
    className?: string;
    iconName?: string;
}

const SellerFooter = (props: SellerFooterProps) => {
    const {t} = useTranslation();
    // const {handleRouteNavigation} = useNavigation();
    return (
        <div className={clsx("SellerFooter col-span-12 w-full flex flex-row items-center justify-between text-white", props.className)}>
            <button
                className={
                    "BookingFooterStepsBack font-medium text-base sm:text-lg rounded-md border sm:leading-5 w-auto basis-auto grow-0 bg-gray-600 border-gray-600 hover:bg-gray-500 hover:border-gray-500 px-4 sm:px-3 py-3 flex flex-row items-center justify-center sm:min-w-[210px]"
                }
                onClick={props.onClickBack ? props.onClickBack : () => {}}
            >
                <span className={"flex-none"}>
                    <ArrowUturnLeftIcon className={"w-6 h-6 flex-none sm:mr-2"} />
                </span>
                <span className={"text-center w-full hidden sm:inline-flex sm:justify-center"}>{t("Back")}</span>
            </button>
            {props.children ? (
                props.children
            ) : props?.onClickNext ? (
                <ButtonSubmit
                    className={clsx(
                        "BookingFooterStepNext",
                        "inline-flex items-center px-4 sm:px-3 py-3 w-auto rounded-md sm:min-w-[210px] !font-medium !text-base sm:!text-lg sm:!leading-none",
                        props.nextStepDisabled ? "bg-gray-300 border-gray-300 text-gray-50 cursor-not-allowed" : "bg-cta-400"
                    )}
                    disabled={props.nextStepDisabled}
                    icon={props?.iconName ? props.iconName : "right"}
                    label={t("Continue")}
                    onClick={props.onClickNext ? props.onClickNext : () => {}}
                />
            ) : (
                <></>
            )}
        </div>
    );
};

export default SellerFooter;
