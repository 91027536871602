import React from "react";
import {PackageItemPersonType} from "types/enums/PackageItemPersonType";
import clsx from "clsx";
import {personTypeLabel} from "utils/utility";

interface PersonTypeTagProps {
    person_type: PackageItemPersonType | null;
}

const PersonTypeTag = (props: PersonTypeTagProps) => {
    return (
        <div className={"PersonTypeTag"}>
            <span
                className={clsx(
                    props.person_type === PackageItemPersonType.Adult && "text-blue-800 bg-blue-100",
                    props.person_type === PackageItemPersonType.Child && "text-green-800 bg-green-100",
                    props.person_type === PackageItemPersonType.Event && "text-amber-800 bg-amber-100",
                    props.person_type === PackageItemPersonType.Family && "text-indigo-800 bg-indigo-100",
                    props.person_type === null && "text-indigo-800 bg-indigo-100",
                    "px-2 py-1 text-xs font-medium rounded-full"
                )}
            >
                {personTypeLabel(props.person_type)}
            </span>
        </div>
    );
};

export default PersonTypeTag;
