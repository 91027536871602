import React, {useState} from "react";
import {useTypedSelector} from "helpers/reducers";
import {useTranslation} from "react-i18next";
import {ShopArticleItem} from "types/entities/ShopArticleItem";
import {appendQueryParams, currencyNumberFormat, translate, truncateString} from "utils/utility";
import {shopArticlePrice, useShopItemsCartHook} from "utils/giftCardShopUtils";
import {ChevronDownIcon, ShoppingCartIcon, TrashIcon} from "@heroicons/react/24/solid";
import {OverlineText} from "pages/Front/components/TextStyles/OverlineText";
import {TAX} from "helpers/config";
import {RouteComponentProps, withRouter} from "react-router-dom";
import clsx from "clsx";
import { Tooltip } from "react-tooltip";

interface Params {}

interface GiftCardShoppingCartProps extends RouteComponentProps<Params> {
    withCheckoutButton: boolean;
}

const GiftCardShoppingCart = (props: GiftCardShoppingCartProps) => {
    const itemsCart = useTypedSelector(state => state.shopState.itemsCart);
    const {removeFromCart} = useShopItemsCartHook();
    const bookingFilter = useTypedSelector(state => state.bookingState.bookingProcess.filter);
    const [itemsCartOpen, setItemsCartOpen] = useState(false);
    const disabled = itemsCart.shopArticleItems.length === 0;
    const {t} = useTranslation();

    const chooseHelper = () => {
        if (disabled) {
            return t("Please select amount of gift cards");
        }
    };

    const proceedToCheckout = () => {
        if (disabled) {
            return t("Please select amount of gift cards");
        }
        const url = "/booking/checkout?" + appendQueryParams(bookingFilter);
        props.history.replace(url);
    };

    return (
        <div
            className={
                "GiftCardShoppingCart relative flex flex-col sticky bottom-0 z-[11] shrink-0 sm:min-w-[360px] rounded-md shadow-md bg-white overflow-hidden"
            }
        >
            <div className={"flex flex-col justify-between relative z-30 grow"}>
                <section className={"shopArticleItems px-4 py-2 sm:py-4"}>
                    <div className={"flex flex-row justify-between"} onClick={() => setItemsCartOpen(!itemsCartOpen)}>
                        <OverlineText text={t("Shopping cart")} />
                        <ChevronDownIcon className={clsx(itemsCartOpen ? "rotate-180" : "", "duration-500 origin-center transform-gpu w-5 h-5 sm:hidden")} />
                    </div>

                    <div
                        className={clsx(
                            itemsCartOpen ? "max-h-[70vh]" : "max-h-0 sm:max-h-[500px]",
                            "shopArticleItemsList divide-y divide-gray-200 overflow-y-auto"
                        )}
                    >
                        <div
                            className={clsx(
                                props.withCheckoutButton ? "grid-cols-[36px,1fr,40px,40px,60px,20px]" : "grid-cols-[36px,1fr,40px,40px,60px]",
                                "grid  text-xs items-center gap-2 my-2"
                            )}
                        >
                            <span>{t("Qty")}</span>
                            <span>{t("Article")}</span>
                            <span>{t("Points")}</span>
                            <span>{t("Person")}</span>
                            <span>{t("Price")}</span>
                            {props.withCheckoutButton && <span></span>}
                        </div>
                        {itemsCart.shopArticleItems.map((item: ShopArticleItem, index) => {
                            return (
                                <div
                                    key={index}
                                    className={clsx(
                                        "ShopArticleItem py-1 text-sm grid",
                                        props.withCheckoutButton ? "grid-cols-[36px,1fr,40px,40px,60px,20px]" : "grid-cols-[36px,1fr,40px,40px,60px]",
                                        "items-center gap-2"
                                    )}
                                >
                                    <b>{item.quantity}x</b>
                                    <span>{translate(item.article.article_name)}</span>
                                    <span>{item.article.race_count_total}</span>
                                    <span className={"text-xs capitalize"}>{truncateString(t(item.article.person_type), 5)}</span>
                                    <span className={"text-xs"}>{shopArticlePrice(item)}</span>
                                    {props.withCheckoutButton && (
                                        <button type="button" onClick={() => removeFromCart(item)}>
                                            <TrashIcon className={"w-4 h-4 text-gray-800"} />
                                        </button>
                                    )}
                                </div>
                            );
                        })}
                    </div>
                </section>
                <section className={"w-full text-sm"}>
                    <Tooltip data-tooltip-float={true} id={"chooseHelp"} />

                    {itemsCart.shopArticleItemsCart && (
                        <div className={"shopArticleItemsTotalPrice bg-gray-300 px-4 py-2 grid grid-cols-[1fr,100px] gap-4"}>
                            <span>
                                {t("Total price")} ({t("incl_tax_rate", {tax_rate: TAX})})
                            </span>
                            <b className={"text-right"}>{currencyNumberFormat(itemsCart.shopArticleItemsCart.price)}</b>
                        </div>
                    )}
                    {props.withCheckoutButton && (
                        <button
                            className={clsx(
                                disabled && "bg-opacity-50 grayscale-[100%] cursor-not-allowed",
                                "bg-cta-800 text-contrast-800 px-6 py-2 sm:py-4 flex flex-row justify-between w-full"
                            )}
                            data-for={"chooseHelp"}
                            data-tip={chooseHelper()}
                            disabled={disabled}
                            type={"button"}
                            onClick={proceedToCheckout}
                        >
                            <b>{t("Proceed to checkout")}</b>
                            <ShoppingCartIcon className={"w-6 h-6 text-contrast-800"} />
                        </button>
                    )}
                </section>
            </div>
        </div>
    );
};

export default withRouter(GiftCardShoppingCart);
