import React, {useEffect, useState} from "react";
import {PackageItem} from "types/entities/PackageItem";
import {useTranslation} from "react-i18next";
import {useDispatch} from "useDispatch";
import {useTypedSelector} from "helpers/reducers";
import {useForm} from "react-hook-form";
import {getSelectedUpsell} from "utils/utility";
import {toast} from "react-toastify";
import BookingAction from "store/booking/BookingAction";
import {PackageItemPersonType} from "types/enums/PackageItemPersonType";
import clsx from "clsx";
import {MinusIcon, PlusIcon} from "@heroicons/react/24/outline";
import {
    GroupedPackageUpsellItemItemContainer,
} from "pages/Front/Booking/components/PackageUpsell/GroupedPackageUpsellItemItemContainer";

interface GroupedPackageUpsellItemItemProps {
    packageItem: PackageItem;
}

const GroupedPackageUpsellItemItem = (props: GroupedPackageUpsellItemItemProps) => {
    const {t} = useTranslation();
    const dispatch = useDispatch();
    const bookingProcess = useTypedSelector(state => state.bookingState.bookingProcess);
    const bookingFilter = useTypedSelector(state => state.bookingState.bookingProcess.filter);
    const {register, watch, setValue} = useForm();
    const upsellCount = watch("upsell-count");
    const [count, setCount] = useState(upsellCount);
    const [showRemoveButton, setShowRemoveButton] = useState(getSelectedUpsell(bookingProcess.upsell, props.packageItem) !== undefined);
    const [callingToAction, setCallingToAction] = useState(false);
    const packageWithPeopleCount = [15100, 15105];
    const [isLoading, setIsLoading] = useState<boolean>(false);
    const minimumValue = packageWithPeopleCount.includes(props.packageItem.article.id)
        ? props.packageItem.extra.whole_group
            ? bookingFilter.adults + bookingFilter.children
            : props.packageItem.minimum_persons_count
        : 1;
    const maximumDriversCount = props.packageItem.maximum_persons_count;
    const setOnChangeHandler = e => {
        const enteredValue = e.target.value;
        if (enteredValue < minimumValue) {
            setValueHandler(minimumValue);
            toast.error(t("cannot order less", {minimum_value: minimumValue}), {toastId: "cannotOrderless"});
            return;
        }
        if (enteredValue > maximumDriversCount) {
            setValueHandler(maximumDriversCount);
            return;
        }
        setValueHandler(enteredValue);
    };

    const setValueHandler = value => {
        setIsLoading(true);
        const currentUpsell = getSelectedUpsell(bookingProcess.upsell, props.packageItem);

        setCount(value);
        setCallingToAction(true);
        if (value === 0 && currentUpsell) {
            dispatch(BookingAction.removeUpsell(currentUpsell));
            setIsLoading(false);
            return;
        }
        dispatch(BookingAction.setUpsell({packageItem: props.packageItem, upsell_option: 0, count: value}));
        setIsLoading(false);
    };

    function setDriversCountHandler(number: number) {
        const newCount = +count + number;
        if (newCount > maximumDriversCount) {
            setValueHandler(maximumDriversCount);
            toast.warning(t("We cannot take more people than: ") + maximumDriversCount, {toastId: "maximumDriverCount"});
            return;
        }

        if (newCount >= 0) {
            setValueHandler(newCount);
        }

        if (newCount < minimumValue) {
            setValueHandler(minimumValue);
            toast.error(t("cannot order less", {minimum_value: minimumValue}), {toastId: "minimumDriverCount"});
            return;
        }
    }

    const getTotalCountByPersonType = (bookingFilter, packageItem: PackageItem) => {
        switch (packageItem.person_type) {
            case PackageItemPersonType.Adult:
                return bookingFilter.adults;
            case PackageItemPersonType.Child:
                return bookingFilter.children;
            case PackageItemPersonType.Family:
                return bookingFilter.adults + bookingFilter.children;
            case PackageItemPersonType.Any:
                return bookingFilter.adults + bookingFilter.children;

            default:
                return bookingFilter.adults;
        }
    };

    useEffect(() => {
        let totalCount = packageWithPeopleCount.includes(props.packageItem.article.id) ? getTotalCountByPersonType(bookingFilter, props.packageItem) : 1;
        if (bookingFilter.adults > maximumDriversCount) {
            totalCount = maximumDriversCount;
        }

        setCount(totalCount);
        if (minimumValue > count) {
            setCount(minimumValue);
        }
        return () => {};
    }, [minimumValue]);

    useEffect(() => {
        const timer = setTimeout(() => {
            setCallingToAction(false);
        }, 500);
        return () => {
            clearTimeout(timer);
        };
    }, [callingToAction]);

    const setSelectedPackageUpsell = (): void => {
        setIsLoading(true);
        const currentUpsell = getSelectedUpsell(bookingProcess.upsell, props.packageItem);
        if (showRemoveButton && currentUpsell) {
            dispatch(BookingAction.removeUpsell(currentUpsell));
            setIsLoading(false);
            // toast.warning(t("Item removed"));

            setShowRemoveButton(false);
            return;
        }

        if (props.packageItem.extra.whole_group) {
            dispatch(
                BookingAction.pushUpsell({
                    packageItem: props.packageItem,
                    upsell_option: 0,
                    count: Number(count),
                })
            );
            setIsLoading(false);
            setShowRemoveButton(true);
            // toast.success(t("Item added"));

            return;
        }
        if (count == 0 && currentUpsell) {
            dispatch(BookingAction.removeUpsell(currentUpsell));
            setIsLoading(false);
            setShowRemoveButton(false);
        }
        if (count > 0) {
            setShowRemoveButton(true);
            dispatch(
                BookingAction.pushUpsell({
                    packageItem: props.packageItem,
                    upsell_option: 0,
                    count: Number(count),
                })
            );
            setIsLoading(false);
        }

        //upsell package goes before book
        // props.history.push("/booking/book/" + PackageItem.package_id );
    };
    const disabled = false;
    const valueButtonClassName =
        (disabled ? "bg-gray-700 cursor-not-allowed" : "bg-arbo-orange border-arbo-orange border bg-opacity-90 cursor-pointer") +
        " h-10 w-10 text-contrast-800 rounded-md hover:no-underline text-3xl flex-grow text-center flex flex-row justify-center items-center";

    useEffect(() => {
        const currentUpsell = getSelectedUpsell(bookingProcess.upsell, props.packageItem);
        currentUpsell?.count && setCount(currentUpsell.count);
    }, [bookingProcess.upsell]);

    return (
        <GroupedPackageUpsellItemItemContainer
            callToAction={callingToAction}
            className={"GroupedPackageUpsellItemItem"}
            inputs={
                <div className={clsx(props.packageItem.extra.whole_group && "was-hidden", "UpsellInputs flex flex-col flex-grow")}>
                    {props.packageItem.extra.max_count === 1 && (
                        <input {...register("upsell-count")} name={"upsell-count"} type={"hidden"} value={props.packageItem.extra.max_count} />
                    )}
                    {props.packageItem.extra.max_count !== 1 && (
                        <label className={clsx("flex flex-row justify-between xl:mb-0.5 gap-x-2")}>
                            <span className={"sr-only"}>{t("Count")}</span>
                            <button className={valueButtonClassName} type={"button"} onClick={() => setDriversCountHandler(-1)}>
                                <MinusIcon className={"w-5 h-5"} />
                            </button>
                            <input
                                {...register("upsell-count")}
                                className={
                                    "font-bold border-2 rounded-md border-gray-500 w-12 xl:w-20 text-gray-900  placeholder-gray-700 hover:appearance-none text-center text-lg py-1"
                                }
                                inputMode={"numeric"}
                                max={props.packageItem.extra.max_count}
                                min={minimumValue}
                                name={"upsell-count"}
                                placeholder={
                                    props.packageItem.person_type === PackageItemPersonType.Adult ? "" + bookingFilter.adults : "" + bookingFilter.children
                                }
                                type={"text"}
                                value={count}
                                onChange={setOnChangeHandler}
                            />
                            <button className={valueButtonClassName} type={"button"} onClick={() => setDriversCountHandler(+1)}>
                                <PlusIcon className={"w-5 h-5"} />
                            </button>
                        </label>
                    )}
                </div>
            }
            isLoading={isLoading}
            packageItem={props.packageItem}
            setSelectedPackageUpsell={setSelectedPackageUpsell}
            showRemoveButton={showRemoveButton}
            style={{}}
        />
    );
};

export default GroupedPackageUpsellItemItem;