import React from "react";
import clsx from "clsx";

interface BookingLoginReservationButtonProps {
    onClick: (payload: any) => void;
    disabled?: boolean;
    type?: "button" | "submit" | "reset";
    children: React.ReactNode | React.ReactNode[];
    className?: string;
}

const BookingLoginReservationButton = (props: BookingLoginReservationButtonProps) => {
    return (
        <button
            className={clsx("BookingLoginReservationButton group disabled:cursor-not-allowed", props.className, props.disabled ? "cursor-not-allowed" : "cursor-pointer")}
            disabled={props.disabled}
            type={props.type || "button"}
            onClick={props.onClick}
        >
            {props.children}
        </button>
    );
};

export default BookingLoginReservationButton;