import React from "react";
import clsx from "clsx";

interface BookingLoginReservationsDetailTableHeadProps {
    className?: string;
    children: React.ReactNode | React.ReactNode[];
}

const BookingLoginReservationsDetailTableHead = (props: BookingLoginReservationsDetailTableHeadProps) => {
    return (
        <thead className={clsx("BookingLoginReservationsDetailTableHead border-b border-black/10 leading-6", props.className)}>
            {props.children}
        </thead>
    );
};

export default BookingLoginReservationsDetailTableHead;