import React from 'react';

interface Props {
    total: number,
}

const SubmitButtonPersonsRequirement = (props: Props) => {
    return (
        <div className={"absolute left-0 h-full bg-cta-800"} style={{width: props.total * 100 + "%"}} />
    );
};

export default SubmitButtonPersonsRequirement;