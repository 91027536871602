import React from "react";
import clsx from "clsx";

interface TableWrapperProps {
    className?: string;
    children: React.ReactNode | React.ReactNode[];
    withoutRounded?: boolean;
}

const TableWrapper = (props: TableWrapperProps) => {
    return (
        <div className={clsx("TanstackTableWrapper bg-white border h-full overflow-hidden", props.className && props.className, props.withoutRounded ? "" : "rounded-md")}>
            <div className={clsx("h-full", props.withoutRounded ? "" : "rounded-md")}>
                <div className={clsx(" h-full overflow-auto", props.withoutRounded ? "" : "rounded-lg")}>
                    <table className={"min-w-full min-h-[350px] h-full relative"}>
                        {props.children}
                    </table>
                </div>
            </div>
        </div>
    );
};

export default TableWrapper;