import React from "react";

interface TableHeadRowProps {
    children: React.ReactNode | React.ReactNode[];
}

const TableHeadRow = (props: TableHeadRowProps) => {
    return (
        <tr className={"px-4"}>
            {props.children}
        </tr>
    );
};

export default TableHeadRow;