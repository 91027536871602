import React from 'react';
import clsx from "clsx";

interface TableHeadColumnInnerWrapperProps {
    className?: string;
    children: React.ReactNode | React.ReactNode[];
}

const TableHeadColumnInnerWrapper = (props: TableHeadColumnInnerWrapperProps) => {
    return (
        <div className={clsx("flex flex-col h-full justify-start", props.className && props.className)}>
            {props.children}
        </div>
    );
};

export default TableHeadColumnInnerWrapper;