import React from "react";
import clsx from "clsx";

interface TitleProps {
    title: string;
    className?: string;
}

const BookingPackageUpsellsHeader = (props: TitleProps) => {
    return <h3 className={clsx("font-sans-serif-k sm:text-xl font-bold text-center py-3", props.className)}>{props.title} </h3>;
};

export default BookingPackageUpsellsHeader;