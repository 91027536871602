import React from "react";
import {Reservation} from "types/entities/Reservation";
import clsx from "clsx";
import BookingCard from "pages/Front/Booking/components/BookingCard";
import {useTranslation} from "react-i18next";
import {useDispatch} from "useDispatch";
import Loader from "components/Loader";
import BookingSellerJournalItem
    from "pages/Front/Booking/Seller/BookingSellerReservationDetail/components/BookingSellerJournalItem";
import {EyeIcon} from "@heroicons/react/24/solid";
import {openModal} from "helpers/actions";
import {ModalSize} from "pages/Front/components/Popup";
import BookingSellerJournalAllModal
    from "pages/Front/Booking/Seller/BookingSellerReservationDetail/components/BookingSellerJournalAllModal";

interface BookingSellerJournalProps {
    className?: string;
    reservation: Reservation;
}

const BookingSellerJournal = (props: BookingSellerJournalProps) => {
    const {t} = useTranslation();
    const dispatch = useDispatch();

    const onClickShowAllHandler = () => {
        dispatch(
            openModal(<BookingSellerJournalAllModal reservationJournals={props.reservation?.reservationJournals} />, {
                modalSize: ModalSize.Scrollable
            })
        );
    }

    if (!props.reservation) {
        return <Loader />;
    }

    return (
        <BookingCard className={clsx("BookingSellerJournal !px-0 !py-0 self-baseline", props.className)}>
            <h2 className={clsx("BookingCardTitle font-bold text-xl text-black px-3 py-2.5 xl:px-3 xl:py-3 flex items-center justify-between border-b")}>
                <div className={"inline-flex items-center gap-x-2"}>
                    <span>{t("Journal")}</span>
                </div>
                <button className={"bg-gray-700 hover:bg-gray-800 px-2 py-1 text-white rounded text-base inline-flex items-center"}
                        onClick={onClickShowAllHandler}>
                    <EyeIcon className={"w-4 h-4 flex-none mr-1.5"} />
                    <span>{t("Show all")}</span>
                </button>
            </h2>

            <div className={"px-3 py-3"}>
                {(!props.reservation?.reservationJournals || (props.reservation.reservationJournals && props.reservation.reservationJournals.length === 0)) && (
                    <div className={"text-center"}>{t("No data")}</div>
                )}
                {props.reservation?.reservationJournals && props.reservation.reservationJournals.length > 0 && (
                    <ul className={"w-full py-0"} role="list">
                        {props.reservation.reservationJournals
                            .sort((a, b) => (a.created_at < b.created_at ? 1 : -1))
                            .slice(0, 2)
                            .map((reservationJournal, index, array) => {
                                return (
                                    <BookingSellerJournalItem key={index} lastItem={array.length - 1 === index}
                                                              reservationJournal={reservationJournal} />
                                )
                            }
                        )}
                    </ul>
                )}
            </div>
        </BookingCard>
    );
};

export default BookingSellerJournal;