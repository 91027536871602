import clsx from "clsx";
import React from "react";

interface DecrementButtonProps extends React.DetailedHTMLProps<React.ButtonHTMLAttributes<HTMLButtonElement>, HTMLButtonElement> {
  size?: string;
  disabled?: boolean;
  onClick?: (e: any) => void;
  increment?: number;
}

const DecrementButton = (props: DecrementButtonProps) => {
  return (
    <button
      className={clsx(
        props.disabled ? "active:mt-0 active:opacity-50" : "active active:opacity-80",
        "decrement-button shadow-button rounded-md inline-block  transform-gpu duration-75 ease-linear  focus:outline-none"
      )}
      onClick={props.onClick}
    >
      <div
        className={clsx(
          props.size ? props.size : "w-28 h-28",
          "relative box-content text-3xl sm:text-5xl pointer preserve-3d flex overflow-hidden p-3 sm:p-4 justify-center items-center bg-cta-800 text-contrast-800 rounded-md shadow-sideways gpu-transform soft-transition"
        )}
        id="minus"
      >
        <div className="relative z-30 flex w-full h-full items-center justify-center">
          <span>{props.increment === -1 ? "-" : "+"}</span>
        </div>
      </div>
    </button>
  );
};

export default DecrementButton;
