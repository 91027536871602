import React from 'react';

interface Props {
    children: React.ReactNode | React.ReactNode[]
}

const BookingUpsellWrap = (props: Props) => {
    return (
        <div className={"BookingPackageUpsells flex flex-col w-full h-full justify-start items-center"}>
            <div className={"BookingPackageUpsellsList relative flex flex-col grow h-full max-w-3xl justify-start items-stretch w-full"}>
                {props.children}
            </div>
        </div>
    );
};

export default BookingUpsellWrap;