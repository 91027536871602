import React, {Fragment, useEffect, useState} from "react";
import {useDebouncedCallback} from "use-debounce";
import {Listbox, Transition} from "@headlessui/react";
import clsx from "clsx";
import {ChevronUpDownIcon} from "@heroicons/react/20/solid";
import {CheckIcon} from "@heroicons/react/24/solid";
import {useTranslation} from "react-i18next";

interface TablePageSizeProps {
    pageSizes: number[];
    selected?: number;
    onChange: (page: number) => void;
    disabled?: boolean;
}

const TablePageSize = (props: TablePageSizeProps) => {
    const {t} = useTranslation();
    const [internalValue, setInternalValue] = useState<number>(props.selected ? props.selected : 10);
    const debouncedChangeHandler = useDebouncedCallback((data) => props.onChange(data), 300);

    const onChangeHandler = (newPage: number) => {
        setInternalValue(newPage);
        debouncedChangeHandler(newPage);
    }

    useEffect(() => {
        setInternalValue(props?.selected ? props.selected : 10);
    }, [props?.selected]);

    return (
        <div className={"relative min-w-[50px] z-10 mr-1.5"}>
            <Listbox disabled={props.disabled} value={internalValue} onChange={onChangeHandler}>
                {({ open }) => (
                    <div className="group rounded">
                        <Listbox.Button
                            className={clsx(
                                "shadow-sm shadow-black/10 ui-open:text-black relative w-full cursor-pointer rounded inline-flex items-center px-2.5 py-1.5 text-base font-medium transition duration-150 border bg-white-200 hover:bg-white group-hover:bg-white",
                                open && "outline outline-1 outline-blue-400 -outline-offset-1"
                            )}
                        >
                            <span>{t("Show")}</span>
                            <span className={clsx("block truncate w-full pl-1 pr-4 text-left")}>{props.pageSizes.find(item => item === internalValue)}</span>
                            <span className={clsx(
                                "pointer-events-none absolute inset-y-0 right-0 flex items-center pr-1 text-gray-800 group-hover:text-blue-400",
                                open && "text-blue-400"
                            )}>
                                <ChevronUpDownIcon
                                    className="h-5 w-5"
                                />
                            </span>
                        </Listbox.Button>
                        <Transition
                            as={Fragment}
                            leave="transition ease-in duration-100"
                            leaveFrom="opacity-100"
                            leaveTo="opacity-0"
                        >
                            <Listbox.Options
                                className="absolute mb-0.5 bottom-full z-10 max-h-60 w-full overflow-auto rounded-md bg-white py-1 text-base sm:text-sm shadow-lg ring-1 ring-black ring-opacity-5 focus:outline-none">
                                {props.pageSizes.map((item, index) => (
                                    <Listbox.Option
                                        key={index}
                                        className={({active}) => (clsx(
                                            "relative cursor-pointer select-none text-base",
                                            active ? "bg-blue-50 text-blue-400" : "text-gray-900"
                                        ))}
                                        value={item}
                                    >
                                        {({selected}) => (
                                            <>
                                                <span
                                                    className={`block py-1.5 pl-6 pr-2 truncate ${
                                                        selected ? 'font-bold' : ' font-normal'
                                                    }`}
                                                >
                                                    {item}
                                                </span>
                                                {selected ? (
                                                    <span
                                                        className="absolute inset-y-0 left-0 flex items-center pl-0.5 text-blue-400">
                                                            <CheckIcon aria-hidden="true" className="h-4 w-5"/>
                                                        </span>
                                                ) : null}
                                            </>
                                        )}
                                    </Listbox.Option>
                                ))}
                            </Listbox.Options>
                        </Transition>
                    </div>
                )}
            </Listbox>
        </div>
    );
};

export default TablePageSize;