import React from "react";
import {useDispatch} from "useDispatch";
import {useTranslation} from "react-i18next";
import {useForm} from "react-hook-form";
import {Reservation} from "types/entities/Reservation";
import {ExclamationCircleIcon, PercentBadgeIcon} from "@heroicons/react/24/outline";
import clsx from "clsx";
import CardComponent from "pages/Front/Booking/components/CardComponent";
import T from "components/T";
import {yupResolver} from "@hookform/resolvers/yup";
import * as yup from "yup";
import {CheckIcon} from "@heroicons/react/24/solid";
import {closeModal} from "helpers/actions";
import {ReservationOfferStatus} from "types/enums/ReservationOfferStatus";
import BookingLoginAction from "store/bookingLogin/BookingLoginAction";
import {consoleLog} from "utils/utility";

interface BookingSellerStatusReasonModalProps {
    reservation: Reservation;
    offerStatus: ReservationOfferStatus;
}

export interface BookingSellerStatusReasonFormInputs {
    reason: string;
}

const BookingSellerStatusReasonModal = (props: BookingSellerStatusReasonModalProps) => {
    const {t} = useTranslation();
    const dispatch = useDispatch();

    const BookingSellerStatusReasonSchema = yup.object().shape({
        reason: yup.string().required("Type a reason."),
    });

    const {
        register,
        handleSubmit,
        formState: {errors},
    } = useForm<BookingSellerStatusReasonFormInputs>({
        resolver: yupResolver(BookingSellerStatusReasonSchema),
        defaultValues: {
            reason: ""
        },
    });

    const onErrorHandler = (dataErrors: any) => {
        consoleLog("onErrorHandler", dataErrors);
    };

    const onSubmitHandler = (data: BookingSellerStatusReasonFormInputs) => {
        dispatch(BookingLoginAction.fetchSetReservationOfferStatusReason(props.reservation.id, props.offerStatus, data.reason)).then(r => {
           dispatch(closeModal());
        });
    }

    return (
        <CardComponent className={"BookingLoginCustomersImportModal md:w-[600px] w-full !p-0 border border-gray-200"}>
            <div
                className={"flex flex-row justify-between w-full px-3 py-3 font-bold border-b-2 border-gray-200 bg-gray-100/20 text-gray-900 drop-shadow-sm"}>
                <div className={"flex flex-row items-center"}>
                    <PercentBadgeIcon className={clsx("w-5 h-5 mr-1 text-gray-600/70 drop-shadow-xl")} />
                    <h4 className={"font-medium text-gray-700"}>{t("Reason for decline")}</h4>
                </div>
            </div>
            <div className={"flex w-full flex-col pb-2"}>
                <form autoComplete={"off"} className={"w-full"} onSubmit={handleSubmit(onSubmitHandler, onErrorHandler)}>
                    <div className={"grid gap-3 px-3 py-3"}>
                        <div className={"col-span-1"}>
                            <label className={clsx("font-medium mb-1 flex", errors?.["reason"] && "text-red-500")}
                                   htmlFor={"label-reason"}>
                                <span>{t("Reason")}</span>
                                <em className={"text-red-500"}>*</em>
                            </label>
                            <p className={"italic text-sm mb-2"}>
                                {t("This status must have a reason for decline. Please give this reason.")}
                            </p>
                            <textarea
                                className={clsx("w-full px-3 py-1.5 rounded-md border-gray-200 border min-h-[200px]", errors?.["reason"] && "border-red-500")}
                                id={"label-reason"}
                                {...register("reason")}
                                placeholder={t("Type reason....")}
                            />
                            {errors?.["reason"]?.message && (
                                <div className={"text-red-500 text-sm mt-1 flex flex-row items-center shrink-0"}>
                                    <ExclamationCircleIcon
                                        className={"min-w-[18px] min-h-[18px] w-[18px] h-[18px] mr-0.5"} />
                                    <T>{errors?.["reason"]?.message}</T>
                                </div>
                            )}
                        </div>
                        <div className={"col-span-1 text-center"}>
                            <button
                                className={clsx(
                                    "border-green-400 bg-green-400 text-white mx-auto flex items-center justify-center font-bold leading-tight py-2 px-4 rounded-md"
                                )}
                                onClick={handleSubmit(onSubmitHandler, onErrorHandler)}
                            >
                                <CheckIcon className={"w-5 h-5 flex-none mr-2"} />
                                <span>{t("Change status")}</span>
                            </button>
                        </div>
                    </div>
                </form>
            </div>
        </CardComponent>
    );
};

export default BookingSellerStatusReasonModal;