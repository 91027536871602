import * as yup from "yup";

const requiredWhenBusiness = {
    is: true,
    then: (yup) => yup.required(),
};


export const CompanySchema = yup.object().shape({
    company_type: yup.boolean().required(),
    company_name: yup.string().when("company_type", requiredWhenBusiness),
    first_name: yup.string().required("Name must be filled"),
    last_name: yup.string().required("Last name must be filled"),
    email: yup.string().email().required("Email must be filled"),
    cin: yup.string(),
    street_address: yup.string().when("company_type", requiredWhenBusiness),
    postal_code: yup.string().when("company_type", requiredWhenBusiness),
    city: yup.string().when("company_type", requiredWhenBusiness),
    tos: yup.bool().oneOf([true], "Field must be checked"),
    reservation_note: yup.string(),
    is_newsletter_enabled: yup.bool(),
    notification_method: yup.string().oneOf(['email', 'phone']),
    phone: yup.string().min(10).required(),
});