import {useEffect, useState} from "react";
import {TIMER_COUNT} from "helpers/config";
import {useTypedSelector} from "helpers/reducers";

interface TimeObject {
    hours: number;
    minutes: number;
    seconds: number;
    difference: number;
}

interface ReturnValues {
    timeLeft: TimeObject;
    error?: string;
}

const useCountdown = ({
    onFirstAlert,
    onSecondAlert,
    onTimeout,
    firstAlertTime,
    secondAlertTime,
}: {
    onFirstAlert: () => void;
    onSecondAlert: () => void;
    onTimeout: () => void;
    firstAlertTime: number;
    secondAlertTime: number;
}): ReturnValues => {
    const packageCart = useTypedSelector(state => state.bookingState.bookingProcess.packageCart);
    const packageCartFirstTimeslot = packageCart?.packagesCartItemSlots?.find((item, index) => index === 0) || null;

    const getTimestamp = (date: string) => {
        return new Date(date).getTime();
    };

    const [updatedAt, setUpdatedAt] = useState<number>(packageCart ? getTimestamp(packageCart.updated_at) : 0);
    const [firstTimeslot, setFirstTimeslot] = useState<number>(packageCart?.packagesCartItemSlots ? packageCart.packagesCartItemSlots[0]?.timestamp : 0);
    const [createdAt, setCreatedAt] = useState<number>(packageCart ? getTimestamp(packageCart.created_at) : 0); // for reference

    useEffect(() => {
        if (packageCart) {
            setUpdatedAt(getTimestamp(packageCart.updated_at));
        }
    }, [packageCart?.updated_at]);

    const calculateTimeLeft = () => {
        const timeNow = new Date().getTime();
        const difference = (updatedAt === 0 ? timeNow : updatedAt) + TIMER_COUNT - timeNow;
        const timeslotDifference = (firstTimeslot === 0 ? (updatedAt === 0 ? timeNow : updatedAt + TIMER_COUNT) : firstTimeslot * 1000 - TIMER_COUNT) - timeNow;
        let resultDifference = difference;
        let timeLeft: TimeObject = {
            hours: 0,
            minutes: 0,
            seconds: 0,
            difference: 0,
        };

        if (timeslotDifference >= 0) {
            resultDifference = timeslotDifference < difference ? timeslotDifference : difference;
        }

        if (resultDifference > 0) {
            timeLeft = {
                hours: Math.floor((resultDifference / (1000 * 60 * 60)) % 24),
                minutes: Math.floor((resultDifference / 1000 / 60) % 60),
                seconds: Math.floor((resultDifference / 1000) % 60),
                difference: resultDifference,
            };
        }
        return timeLeft;
    };

    const [timeLeft, setTimeLeft] = useState<TimeObject>(calculateTimeLeft());

    //update timeslots on each change of packageCart
    useEffect(() => {
        if (packageCartFirstTimeslot) {
            setFirstTimeslot(packageCartFirstTimeslot.timestamp);
        }
    }, [packageCart]);

    //provide callbacks in set time levels
    useEffect(() => {
        const countDown = setTimeout(() => {
            let realTimeLeft;
            const timeLeftNow = calculateTimeLeft();

            if (timeLeft !== timeLeftNow) {
                realTimeLeft = timeLeftNow;
            } else {
                realTimeLeft = timeLeft;
            }
            //because timeleft before initialization is 0, setTimeLeft only works in state and is updated after this funcion is done.
            setTimeLeft(calculateTimeLeft());

            if (realTimeLeft.difference === firstAlertTime) {
                onFirstAlert();
            }

            if (realTimeLeft.difference === secondAlertTime) {
                onSecondAlert();
            }

            if (realTimeLeft.difference === 0) {
                onTimeout();
            }
        }, 500);

        return () => {
            clearTimeout(countDown);
        };
    });

    if (!packageCart) {
        // console.error("Package Cart is null!");
        return {timeLeft, error: "Package Cart is null!"};
    }

    return {timeLeft, error: ""};
};

export default useCountdown;
