import React from "react";
import {closeModal} from "helpers/actions";
import {useDispatch} from "useDispatch";
import {XMarkIcon} from "@heroicons/react/24/solid";

interface ModalScrollableCloseProps {
}

const ModalScrollableClose = (props: ModalScrollableCloseProps) => {
    const dispatch = useDispatch();

    const onCloseModalHandler = () => {
        dispatch(closeModal());
    }

    return (
        <button
            className={"ModalScrollableClose print:hidden bg-red-500 hover:bg-red-600 text-white absolute right-0 top-0 z-[100] rounded-tr-md p-2"}
            onClick={onCloseModalHandler}
        >
            <XMarkIcon className={"text-white hover:opacity-70 w-8"} />
        </button>
    );
};

export default ModalScrollableClose;