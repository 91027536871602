import React from "react";
import clsx from "clsx";

interface BookingLoginReservationsDetailTableBodyProps {
    className?: string;
    children: React.ReactNode | React.ReactNode[];
}

const BookingLoginReservationsDetailTableBody = (props: BookingLoginReservationsDetailTableBodyProps) => {
    return (
        <tbody className={clsx("BookingLoginReservationsDetailTableBody divide-y divide-black/5", props.className)}>
            {props.children}
        </tbody>
    );
};

export default BookingLoginReservationsDetailTableBody;