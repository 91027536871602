import React from "react";
import {filterPackageItemsByType} from "utils/utility";
import {PersonType} from "types/enums/PersonType";
import {useTranslation} from "react-i18next";
import {Package} from "types/entities/Package";

interface PackagePersonsType {
    packageDetail: Package;
}
const PackageDetailContentsByPersonType = (props: PackagePersonsType) => {
    const {t} = useTranslation();
    return (
        <span className="PackageDetailContentsByPersonType">
            {filterPackageItemsByType(props.packageDetail, PersonType.Adult).length > 0 &&
                filterPackageItemsByType(props.packageDetail, PersonType.Child).length > 0 && (
                    <em className={"text-sm"}>&nbsp;({t("for either adult or child")})</em>
                )}
            {filterPackageItemsByType(props.packageDetail, PersonType.Adult).length > 0 &&
                filterPackageItemsByType(props.packageDetail, PersonType.Child).length === 0 && <em className={"text-sm"}>&nbsp;({t("for adults")})</em>}
            {filterPackageItemsByType(props.packageDetail, PersonType.Adult).length === 0 &&
                filterPackageItemsByType(props.packageDetail, PersonType.Child).length > 0 && <em className={"text-sm"}>&nbsp;({t("for children")})</em>}
        </span>
    );
};

export default PackageDetailContentsByPersonType;