import React, {useState} from "react";
import Lottie from "lottie-react";
import SuccessAnimation from "components/Animations/SuccessAnimation.json";
import {StrongText} from "pages/Front/components/TextStyles/StrongText";
import {MutedText} from "pages/Front/components/TextStyles/MutedText";
import clsx from "clsx";
import {Link} from "react-router-dom";
import {HomeIcon} from "@heroicons/react/24/solid";
import T from "components/T";
import {useTranslation} from "react-i18next";

interface SellerForgottenPasswordRecoverySuccessProps {
}

const SellerForgottenPasswordRecoverySuccess = (props: SellerForgottenPasswordRecoverySuccessProps) => {
    const {t} = useTranslation();
    const [accessComplete, setAccessComplete] = useState<boolean>(false);

    return (
        <div
            className={"SellerForgottenPasswordRecoverySuccess relative max-w-md z-3 flex flex-col space-y-4 gap-2 items-center justify-between"}>
            <div className={"flex flex-col justify-center items-center space-y-4"}>
                <div className={"w-20 h-20 rounded-full shadow-lg p-4 bg-green-100"}>
                    <Lottie
                        animationData={SuccessAnimation}
                        loop={false}
                        onComplete={() => {
                            setAccessComplete(true);
                        }}
                    />
                </div>
                <StrongText text={t("Password changed successfully")} />
                <MutedText className={"text-center"}
                    text={t("Continue to login from the main page. Please, store your password in a safe place.")} />
            </div>
            <div className={"flex flex-col justify-center items-center"}>
                <div
                    className={clsx(accessComplete ? "opacity-100" : "opacity-0", "duration-500 ease-in-out transform-gpu")}>
                    <div className={"flex items-center shrink justify-self-start"}>
                        <Link
                            className={"flex items-center gap-2 py-2 px-4 border-2 border-green-500 hover:border-gray-300 text-green-500 font-bold rounded-lg"}
                            to={"/booking/seller/login-with-password"}
                        >
                            <HomeIcon className={"w-4 h-4 flex-none"} />
                            <T>Back to main</T>
                        </Link>
                    </div>
                </div>
            </div>
        </div>
    );
};

export default SellerForgottenPasswordRecoverySuccess;