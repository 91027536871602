import React from "react";
import {Package} from "types/entities/Package";
import {useTranslation} from "react-i18next";
import {CheckIcon, ExclamationTriangleIcon, UserIcon, UsersIcon} from "@heroicons/react/24/outline";
import {filterFitsWithPackageRequirements} from "utils/packageUtils";
import clsx from "clsx";
import {useTypedSelector} from "helpers/reducers";

interface PackageRequirementsProps {
    package: Package | null;
}

interface PackageRequirementsItemProps {
    label: string;
    icon: string;
    value: string;
    valid?: boolean;
    total: number;
}

const PackageRequirementsItem = (props: PackageRequirementsItemProps) => {
    return (
        <div className={"PackageRequirementsItem flex flex-row"}>
            <div
                className={clsx(
                    props.total === 1 ? "bg-primary-800 text-white" : "bg-gray-100 text-primary-800",
                    // "bg-gray-100 text-gray-800",
                    "flex flex-row rounded-xl relative overflow-hidden",
                )}
            >
                <div className={"flex flex-row px-2 py-1 box-border space-x-2 items-center z-20"}>
                    <span>{props.label}</span>
                    {props.valid ? (
                        props.valid === true ? (
                            <CheckIcon className={"w-4 h-4 mr-1"} />

                        ) : (
                            <ExclamationTriangleIcon className={"w-4 h-4 mr-1"} />
                        )
                    ) : (
                        <>
                            {props.icon === "minimum" && <UserIcon className={"w-4 h-4 mr-1"} />}
                            {props.icon === "maximum" && <UsersIcon className={"w-4 h-4 mr-1"} />}
                        </>
                    )}
                    <span>{props.value}</span>
                </div>
                <div className={"absolute left-0 h-full bg-cta-800 z-10"} style={{width: props.total * 100 + "%"}} />
            </div>
        </div>
    );
};

const getPersonsTypeFraction = (totalPersons, limit) => (totalPersons <= limit) ? totalPersons / limit : 1;

const PackageRequirements = (props: PackageRequirementsProps) => {
    const {t} = useTranslation();
    const bookingProcess = useTypedSelector(state => state.bookingState.bookingProcess);
    const bookingFilter = bookingProcess.filter;
    const disabled = filterFitsWithPackageRequirements(props.package, bookingFilter);
    const totalPersons = +bookingFilter.adults + +bookingFilter.children;
    // const total = totalPersons <= props.package?.min_drivers_count ? totalPersons / props.package.min_drivers_count
    return (
        <div className={"PackageRequirements flex flex-col justify-center items-center space-y-4"}>
            <h3 className={"font-bold text-center"}>{t("Package requirements")}</h3>
            {props.package && (
                <div className={"flex flex-row flex-wrap justify-between w-full space-x-1 text-xs sm:text-base"}>
                    <PackageRequirementsItem
                        icon={"minimum"} label={t("Min persons")}
                        total={getPersonsTypeFraction(totalPersons, props.package.min_drivers_count)} valid={!disabled} value={"" + props.package.min_drivers_count} />
                    <PackageRequirementsItem
                        icon={"maximum"} label={t("Max persons")}
                        total={getPersonsTypeFraction(totalPersons, props.package.max_drivers_count)} valid={!disabled} value={"" + props.package.max_drivers_count} />
                </div>
            )}
        </div>
    );
};

export default PackageRequirements;
