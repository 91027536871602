import React, {useEffect, useState} from "react";
import {useDebouncedCallback} from "use-debounce";

interface TableFilterDebouncedInputProps extends React.InputHTMLAttributes<HTMLInputElement> {
    value: string | number;
    customOnChange: (value: string | number) => void;
    resetValue?: boolean;
    resetValueChanged?: (value: boolean) => void;
    debounceTime?: number;
}

const TableFilterDebouncedInput: React.FC<TableFilterDebouncedInputProps> = (
    {
        value,
        customOnChange,
        resetValue,
        resetValueChanged,
        debounceTime = 300,
        ...restProps
    }
) => {
    const [internalValue, setInternalValue] = useState(value);
    const {type, min, max} = restProps;

    const debouncedChangeHandler = useDebouncedCallback((data) => customOnChange(data), debounceTime);

    const onChangeHandler = (newValue: string | number | undefined) => {
        if (type === "number" && Number(newValue) < Number(min)) {
            newValue = min;
        }
        if (type === "number" && Number(newValue) > Number(max)) {
            newValue = max;
        }
        setInternalValue(newValue ? newValue : "");
        debouncedChangeHandler(newValue);
    }

    useEffect(() => {
        if(resetValue) {
            setInternalValue("");
            resetValueChanged && resetValueChanged(false);
        }
    }, [resetValue]);

    return (
        <input
            value={internalValue}
            onChange={e => onChangeHandler(e.target.value)}
            {...restProps}
        />
    );
};

export default TableFilterDebouncedInput;