import React from "react";
import {PackageItem} from "types/entities/PackageItem";
import ScheduleItem from "pages/Front/Booking/components/PackageDetail/ScheduleItem";
import {getBreakClaimText, translate} from "utils/utility";
import {useTranslation} from "react-i18next";
import {ActivityType} from "types/enums/ActivityType";
import {useTypedSelector} from "helpers/reducers";
import {Package} from "types/entities/Package";
import {PackageCategory} from "types/enums/PackageCategory";
import {HallCode} from "types/enums/HallCode";
import { isKartingPackageItem } from "utils/packageUtils";

export interface PackageItemGameProps {
    packageItemIndex: string | number;
    packageItem: PackageItem;
    packageDetail: Package;
    gameIndex: number;
    type: string;
}

const PackageItemGame = (props: PackageItemGameProps) => {
    const {t} = useTranslation();
    const activities = useTypedSelector(state => state.bookingState.activities);
    const hall = useTypedSelector(state => state.hallState.hall);
    const eventKartingRacesName = (index: number): string => {
        switch (index) {
            case 0:
                return t("learning race");
            case 1:
                return t("qualify race");
            case 2:
                return t("final race");
            default:
                return t("race");
        }
    };



    const packageItemLabel = (packageItem: PackageItem) => {
        const raceCount = packageItem.article.race_count_total;
        return isKartingPackageItem(packageItem, activities)
            ? translate(packageItem.name) + (raceCount > 1 ? " | " + eventKartingRacesName(props.gameIndex) : "")
            : translate(packageItem.name);
    };

    return (
        <div key={props.packageItemIndex} className="PackageItemGame w-full" data-packageitemgameindex={props.packageItem.id + "-" + props.gameIndex}>
            {props.type === "game" ? (
                <ScheduleItem
                    duration={" ~ " + props.packageItem.duration}
                    label={t("Break time")}
                    value={props.packageDetail.category === PackageCategory.Event ? "" : getBreakClaimText(0, props.type)}
                />
            ) : activities && activities.find(activity => activity.id === props.packageItem.activity_id)?.type === ActivityType.TheParkPlayground ? (
                <ScheduleItem
                    duration={props.packageItem.duration < 20 ? "5" : "30"}
                    label={t("Introduction to VR")}
                    value={t("Explore the playground of Virtual Reality")}
                />
            ) : props.packageItem.activity_id === null ? (
                <ScheduleItem duration={"5"} label={t("Move to the bar")} value={t("The activities are over, it's time for a drink")} />
            ) : activities && activities.find(activity => activity.id === props.packageItem.activity_id)?.type === ActivityType.Petangue ? (
                <></>
            ) : (
                <ScheduleItem
                    duration={props.packageItem.duration < 20 ? "5" : "15"}
                    label={t("Safety instructions")}
                    value={props.packageDetail.category === PackageCategory.Event ? "" : t("Mandatory instructions for your safety")}
                />
            )}
            {props.packageDetail.category === PackageCategory.Event ? (
                <ScheduleItem
                    key={props.packageItemIndex}
                    duration={props.packageItem.duration}
                    label={packageItemLabel(props.packageItem)}
                    value={hall && hall.hall_code === HallCode.Zwolle ? translate(props.packageItem.description) : ""}
                />
            ) : (
                <ScheduleItem
                    key={props.packageItemIndex}
                    duration={props.packageItem.duration}
                    label={translate(props.packageItem.name)}
                    value={translate(props.packageItem.description)}
                />
            )}
        </div>
    );
};

export default PackageItemGame;
