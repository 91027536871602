import React from "react";
import clsx from "clsx";

interface BookingLoginTableHeadProps {
    className?: string;
    children: React.ReactNode | React.ReactNode[];
}

const BookingLoginTableHead = (props: BookingLoginTableHeadProps) => {
    return (
        <div className={clsx("BookingLoginTableHead grid items-center py-1.5 font-medium divide-x divide-gray-200 border-b-2 border-gray-200 bg-gray-100/20 text-gray-900 rounded-tl-md rounded-tr-md drop-shadow-sm", props.className)}>
            {props.children}
        </div>
    );
};

export default BookingLoginTableHead;