import React from "react";
import {FieldErrors} from "react-hook-form/dist/types/errors";
import clsx from "clsx";
import CompanyFormLabel from "pages/Front/Booking/components/CompanyForm/CompanyFormLabel";
import {useTranslation} from "react-i18next";
import T from "components/T";
import {
    AtSymbolIcon,
    BuildingOffice2Icon, ChatBubbleBottomCenterTextIcon,
    EnvelopeOpenIcon,
    MapIcon,
    MapPinIcon, PhoneIcon,
    RectangleStackIcon, UserIcon, UsersIcon,
} from "@heroicons/react/24/outline";

interface CompanyFormInputProps {
    errors: FieldErrors;
    register: any;
    name: string;
    label?: string;
    type?: string;
    className?: string;
    classNameLabel?: string;
    placeholder?: string;
    required?: boolean;
    disabled?: boolean;
    iconName?: string;
}

const CompanyFormInput = (props: CompanyFormInputProps) => {
    const {t} = useTranslation();
    const iconClass = "h-5 w-5 text-black text-opacity-30";
    return (
        <div className={clsx("CompanyFormInput", props.className)}>
            {props.label &&
                <CompanyFormLabel
                    className={props.classNameLabel}
                    errors={props.errors}
                    label={props.label}
                    name={props.name}
                    required={props.required}
                />
            }
            <div className={"relative rounded-md shadow-sm"}>
                {props.iconName &&
                    <div className={"absolute inset-y-0 h-full left-0 pl-3 flex items-center pointer-events-none z-20"}>
                        {props.iconName === "company_name" &&
                            <BuildingOffice2Icon aria-hidden="true" className={iconClass} />}
                        {props.iconName === "cin" && <RectangleStackIcon aria-hidden="true" className={iconClass} />}
                        {props.iconName === "street_address" && <MapPinIcon aria-hidden="true" className={iconClass} />}
                        {props.iconName === "city" && <MapIcon aria-hidden="true" className={iconClass} />}
                        {props.iconName === "postal_code" &&
                            <EnvelopeOpenIcon aria-hidden="true" className={iconClass} />}
                        {props.iconName === "first_name" && <UserIcon aria-hidden="true" className={iconClass} />}
                        {props.iconName === "name" && <UserIcon aria-hidden="true" className={iconClass} />}
                        {props.iconName === "last_name" && <UsersIcon aria-hidden="true" className={iconClass} />}
                        {props.iconName === "email" && <AtSymbolIcon aria-hidden="true" className={iconClass} />}
                        {props.iconName === "phone" && <PhoneIcon aria-hidden="true" className={iconClass} />}
                        {props.iconName === "message" &&
                            <ChatBubbleBottomCenterTextIcon aria-hidden="true" className={iconClass} />}
                    </div>
                }
                <input
                    {...props?.register && {...props?.register(props.name)}}
                    className={clsx(
                        "w-full py-3 mt-0 border rounded-md shadow-xs focus:outline-none active:ring-cta-800 active:ring-2 active:ring-offset-2 focus:border-cta-800 checked:bg-cta-800 sm:text-sm",
                        props.iconName ? "pl-10 pr-3" : "px-3",
                        props.errors?.[props.name] ? "ring-1 ring-red-500 border-red-500" : "border-gray-800",
                        props.disabled && "grayscale bg-gray-400",
                    )}
                    disabled={props.disabled}
                    id={"input-" + props.name}
                    name={props.name}
                    placeholder={props?.placeholder && t(props.placeholder)}
                    type={props?.type ? props.type : "text"}
                />
            </div>
            {props.errors?.[props.name]?.message && (
                <div className={"text-red-500 text-sm mt-1 flex flex-row"}>
                    <T>{props.errors?.[props.name]?.message}</T>
                </div>
            )}
        </div>
    );
};

export default CompanyFormInput;