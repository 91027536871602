import React from "react";
import clsx from "clsx";
import {useTranslation} from "react-i18next";

interface BookingLoginTableRowColumnResponsiveProps {
    className?: string;
    children: React.ReactNode | React.ReactNode[];
    label?: string
    classNameLabel?: string;
    classNameValue?: string;
}

const BookingLoginTableRowColumnResponsive = (props: BookingLoginTableRowColumnResponsiveProps) => {
    const {t} = useTranslation();
    return (
        <div
            className={clsx(
                "BookingLoginTableRowColumnResponsive col-span-1 !flex flex-col grow-0 basic-auto md:flex-auto md:!inline-flex py-1.5 lg:py-1 px-2 md:px-1.5",
                props.className
            )}
        >
            {props.label &&
                <div className={clsx("flex md:hidden font-bold w-full", props.classNameLabel)}>{t(props.label)}</div>
            }
            <div className={clsx("flex flex-row w-full", props.classNameValue)}>
                {props.children}
            </div>
        </div>
    );
};

export default BookingLoginTableRowColumnResponsive;