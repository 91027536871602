import React from "react";
import {useTranslation} from "react-i18next";
import {useTypedSelector} from "helpers/reducers";
import {HallCode} from "types/enums/HallCode";
import {DEFAULT_HALL} from "helpers/config";
import {GroupTypeId} from "types/enums/GroupTypeId";
import CardLabel from "pages/Front/Booking/components/BookingCustomer/CardLabel";

interface GroupTypeFilterProps {
    register: any;
    showTitle?: boolean;
    onChange: (number) => void;
}

export interface GroupType {
    name: string;
    description: string;
    id: GroupTypeId;
    persons: number;
}

const GroupTypeFilter = (props: GroupTypeFilterProps) => {
    const {t} = useTranslation();
    const packages = useTypedSelector(state => state.bookingState.packages);
    const bookingFilter = useTypedSelector(state => state.bookingState.bookingProcess.filter);
    const minimumSize = DEFAULT_HALL === HallCode.Spreitenbach;
    const minimumPersons = minimumSize ? 6 : 8;
    const groupTypes: GroupType[] = [
        {
            name: t("Company"),
            description: t("from number persons", {count: minimumPersons}),
            id: GroupTypeId.Company,
            persons: minimumSize ? 6 : 8,
        },
        {
            name: t("Bachelor's party"),
            description: t("from number persons", {count: minimumPersons}),
            id: GroupTypeId.Bachelor,
            persons: minimumSize ? 6 : 8,
        },
        {
            name: t("Friends' outing"),
            description: t("from number persons", {count: minimumPersons}),
            id: GroupTypeId.Friends,
            persons: minimumSize ? 6 : 8,
        },
        {
            name: t("Children's party"),
            description: t("from number persons", {count: 6}),
            id: GroupTypeId.Children,
            persons: 6,
        },
        {
            name: t("Gift cards"),
            description: t("from number persons", {count: 1}),
            id: GroupTypeId.GiftCard,
            persons: 1,
        },
    ];

    const filteredGroupTypes = () => {
        const validGroupTypes = packages.map(Package => Package.extra.groupType);
        return groupTypes.filter(groupType => {
            return validGroupTypes.filter(validGroupType => {
                if(validGroupType) {
                    return validGroupType.filter(grt => grt === groupType.id);
                }
                return false;
            })
        });
    };
    return (
        <div className={"GroupTypeFilter flex flex-col space-y-4 bg-white rounded-sm overflow-hidden"}>
            {props.showTitle && (
                <div className={"GroupTypeFilterTitle"}>
                    <CardLabel title={t("Group type")} />
                </div>
            )}
            <fieldset>
                <div className="border-t border-b border-gray-200 divide-y divide-gray-200">
                    {groupTypes.map((group: GroupType, key: number) => {
                        const checkedType = bookingFilter.groupType.includes("" + group.id);

                        if (!filteredGroupTypes().includes(group)) {
                            return null;
                        }
                        return (
                            <div key={key} className="GroupType relative flex items-start py-4">
                                <div className="mr-3 flex items-center h-5 cursor-pointer">
                                    <input
                                        {...props.register}
                                        aria-describedby={`group-${group.id}`}
                                        checked={checkedType}
                                        className="focus:ring-cta-800 checked:bg-cta-800 h-4 w-4 text-cta-800 border-cta-100 rounded cursor-pointer"
                                        id={`group-${group.id}`}
                                        name={`groupType[]`}
                                        type="checkbox"
                                        value={group.id}
                                        onChange={props.onChange}
                                    />
                                </div>
                                <div className="min-w-0 flex flex-grow text-sm">
                                    <label
                                        className="font-medium text-gray-900 w-full flex justify-between flex-grow select-none cursor-pointer"
                                        htmlFor={`group-${group.id}`}
                                    >
                                        {t(group.name)}
                                        <span className={"text-gray-700 text-xs"}>{t(group.description)}</span>
                                    </label>
                                </div>
                            </div>
                        );
                    })}
                </div>
            </fieldset>
        </div>
    );
};

export default GroupTypeFilter;
