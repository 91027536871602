import React from "react";
import {useTranslation} from "react-i18next";
import Lottie from "lottie-react";
import SuccessAnimation from "components/Animations/SuccessAnimation.json";
import {StrongText} from "pages/Front/components/TextStyles/StrongText";
import {MutedText} from "pages/Front/components/TextStyles/MutedText";
import {Link} from "react-router-dom";
import {HomeIcon} from "@heroicons/react/24/solid";

interface SellerForgottenPasswordRecoveryAccessTokenRequestedProps {
}

const SellerForgottenPasswordRecoveryAccessTokenRequested = (props: SellerForgottenPasswordRecoveryAccessTokenRequestedProps) => {
    const {t} = useTranslation();

    return (
        <div
            className={"SellerForgottenPasswordRecoveryAccessTokenRequested relative max-w-md z-3 flex flex-col space-y-4 gap-2 items-center justify-between"}>
            <div className={"flex flex-col justify-center items-center space-y-4"}>
                <div className={"w-20 h-20 rounded-full shadow-lg p-4 bg-green-100"}>
                    <Lottie animationData={SuccessAnimation} loop={false} />
                </div>
                <StrongText text={t("Recovery e-mail sent successfully")} />
                <MutedText className={"text-center"} text={t("Please check your email to access the recovery process. If you haven't received the email, check for spam folder.")} />
                <MutedText className={"text-center"} text={t("In case you haven't received the recovery email in within 15 minutes, please contact support")} />
            </div>
            <div className={"flex flex-col"}>
                <div className={"flex items-center shrink justify-self-start"}>
                    <Link
                        className={"flex items-center gap-2 py-2 px-4 border-2 border-green-500 hover:border-gray-300 text-green-500 font-bold rounded-lg"}
                        to={"/booking/seller/login-with-password"}
                    >
                        <HomeIcon className={"w-4 h-4 flex-none"} />
                        <span>{t("Back to login")}</span>
                    </Link>
                </div>
            </div>
        </div>
    );
};

export default SellerForgottenPasswordRecoveryAccessTokenRequested;