import {QuestionMarkCircleIcon} from "@heroicons/react/24/solid";
import React from "react";
import {useTranslation} from "react-i18next";

const NeedHelpWithTimeslots = (props: {onClick: () => void}) => {
    const {t} = useTranslation();
    return (
        <div className={"NeedHelpWithTimeslots w-full flex justify-center"}>
            <button className={"p-2  flex flex-row items-center text-xs hover:underline"} type={"button"} onClick={props.onClick}>
                <QuestionMarkCircleIcon className={"w-5 h-5"} />
                <span>{t("Need help?")}</span>
            </button>
        </div>
    );
};

export default NeedHelpWithTimeslots;
