import React, {useEffect} from "react";
import {useTypedSelector} from "helpers/reducers";
import {RouteComponentProps, withRouter} from "react-router-dom";
import {openModal} from "helpers/actions";
import BookingFilterMinimal from "pages/Front/Booking/components/BookingFilters/BookingFilterMinimal";
import {useDispatch} from "useDispatch";
import {useTranslation} from "react-i18next";
import PackageRequirements from "pages/Front/Booking/components/PackageDetail/PackageRequirements";
import {getPackageItemsWithGroupOptions, translate, uniqueFilter} from "utils/utility";
import BookingUpsellWrap from "pages/Front/Booking/components/PackageUpsell/BookingUpsellWrap";
import BookingPackageUpsellWithoutGroup
    from "pages/Front/Booking/components/PackageUpsell/BookingPackageUpsellWithoutGroup";
import BookingPackageUpsellWithGroup from "pages/Front/Booking/components/PackageUpsell/BookingPackageUpsellWithGroup";
import BookingUpsellFooter from "pages/Front/Booking/components/PackageUpsell/BookingUpsellFooter";

interface Params {
    package_id: string;
}

interface BookingPackageUpsellsProps extends RouteComponentProps<Params> {
}

const BookingPackageUpsells = (props: BookingPackageUpsellsProps) => {
    const dispatch = useDispatch();
    const {t} = useTranslation();
    const Package = useTypedSelector(state => state.bookingState.bookingProcess.package);
    const bookingProcess = useTypedSelector(state => state.bookingState.bookingProcess);
    const bookingFilter = bookingProcess.filter;

    useEffect(() => {
        if (+bookingFilter.adults + +bookingFilter.children === 0) {
            dispatch(
                openModal(
                    <BookingFilterMinimal filterMinimal={true} package={bookingProcess.package} proceedToUpsell={false}>
                        <PackageRequirements package={bookingProcess.package} />
                    </BookingFilterMinimal>,
                    {},
                ),
            );
        }
    }, []);

    if (!Package) return null;

    const packageItemsWithGroupOptions = getPackageItemsWithGroupOptions(Package);
    const groupOptions = packageItemsWithGroupOptions.map(packageItem => packageItem.extra.group);
    const uniqueGroupOptions: number[] = uniqueFilter(groupOptions);
    return (
        <BookingUpsellWrap>
            {/*<h3 className={"sm:text-2xl font-bold sm:mt-2 text-center h-8 sm:h-10"}>*/}
            {/*    {t("Selected package")}:<span className={"text-cta-800"}> {translate(Package.name)}</span>*/}
            {/*</h3>*/}
            <div className={"flex flex-col grow relative overflow-y-auto space-y-12 pb-10 px-1 z-10"}>
                {/*<div className={"absolute inset-0"}>*/}
                {/*//list out packages that are an group option*/}
                {uniqueGroupOptions.length > 0 && (
                    <BookingPackageUpsellWithGroup Package={Package} bookingProcess={bookingProcess}
                                                   uniqueGroupOptions={uniqueGroupOptions} />
                )}

                {/*//last list out packages that are not an group option*/}
                <BookingPackageUpsellWithoutGroup Package={Package} />
                {/*</div>*/}
            </div>
            <div className={"relative sticky bottom-0 -mb-[3.75rem] z-[30]"}>
                <div className="absolute w-[98vw] -translate-x-1/2 left-1/2 border-gray-600 border-t"></div>
                <div className={"h-20 inset-x-0 py-2 flex flex-col items-center bg-gray-100"}>
                    <div className={"w-full p-2"}>
                        <BookingUpsellFooter Package={Package} bookingFilter={bookingFilter} />
                    </div>
                </div>
            </div>
        </BookingUpsellWrap>
    );
};

export default withRouter(BookingPackageUpsells);
