import React from "react";
import CardComponent from "pages/Front/Booking/components/CardComponent";
import SellerForgottenPasswordForm
    from "pages/Front/Booking/Seller/SellerForgottenPassword/SellerForgottenPasswordForm";
import {withRouter} from "react-router";

interface SellerForgottenPasswordProps {
}

const SellerForgottenPassword = (props: SellerForgottenPasswordProps) => {
    return (
        <div className={"BookingSellerForgotPassword w-full flex justify-center items-center"}>
            <CardComponent className={"w-full max-w-md"}>
                <SellerForgottenPasswordForm />
            </CardComponent>
        </div>
    );
};

export default withRouter(SellerForgottenPassword);