import React from 'react';
import {XMarkIcon} from "@heroicons/react/24/solid";
import clsx from "clsx";

interface TableFilterRemoveButtonProps {
    inputValue?: unknown;
    onClick: () => void;
    className?: string
    classNameIcon?: string
}

const TableFilterRemoveButton = (props: TableFilterRemoveButtonProps) => {
    return (
        <button className={clsx("absolute -right-6", props.className, !props.inputValue && "border-r-0")} type={"button"} onClick={props.onClick}>
            <XMarkIcon className={clsx("w-5 h-5 ml-1", props.classNameIcon && props.classNameIcon, (!props.inputValue) ? "text-gray-500 opacity-0" : "text-red-500")} />
        </button>
    );
};

export default TableFilterRemoveButton;