import React from "react";
import clsx from "clsx";

interface YearlyPackageInfoProps {
    headerLine: string;
    articleName: string;
    descriptionTitle: string;
    points: string[]
    className?: string;
}

const YearlyPackageInfo = (props: YearlyPackageInfoProps) => {
    return (
        <div className={clsx("flex-1 h-full pt-6 sm:pt-4 md:pt-5 lg:pt-6 border border-dotted border-t-0 border-gray-100 rounded-tl-none rounded-tr-none rounded-xl bg-gradient-to-b from-white to-gray-50 p-4 ", props.className)}>
            <h3 className={"text-xl lg:text-2xl font-bold text-center leading-tight"}>{props.headerLine}</h3>
            <div className={"italic mt-1 text-center leading-tight"}>{props.descriptionTitle}</div>
            <div className={"font-bold text-center leading-none text-gray-300 mt-3 mb-2 lg:mt-4 lg:mb-3"}>---</div>
            <h4 className={"font-medium text-lg lg:text-xl uppercase text-center mb-2"}>{props.articleName}</h4>
            <ul className={"list-none pl-6"}>
                {props.points.map((point, index) => (
                    <li
                        key={index}
                        className="relative before:content-['✔'] before:absolute before:-left-6 before:text-green-500"
                    >
                        {point}
                    </li>
                ))}
            </ul>
        </div>
    );
};

export default YearlyPackageInfo;