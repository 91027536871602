import React from "react";
import clsx from "clsx";

interface BookingLoginReservationsDetailTableRowProps {
    className?: string;
    children: React.ReactNode | React.ReactNode[];
}

const BookingLoginReservationsDetailTableRow = (props: BookingLoginReservationsDetailTableRowProps) => {
    return (
        <tr className={clsx("BookingLoginReservationsDetailTableRow", props.className)}>{props.children}</tr>
    );
};

export default BookingLoginReservationsDetailTableRow;