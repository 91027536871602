import React, {useEffect, useState} from "react";
import useCountdown from "utils/hooks/useCountdown";
import {padMinutes} from "utils/utility";
import Loader from "components/Loader";
import {ClockIcon} from "@heroicons/react/24/outline";
import {useTranslation} from "react-i18next";
import clsx from "clsx";
import {toast} from "react-toastify";
import Dates from "utils/Dates";
import {useDispatch} from "useDispatch";
import {RouteComponentProps, withRouter} from "react-router-dom";
import BookingAction from "store/booking/BookingAction";
import VoucherAction from "store/shop/VoucherAction";
import {ThunkAction} from "redux-thunk";
import useNavigation from "utils/hooks/useNavigation";

interface Props extends RouteComponentProps {}

const CountdownTimer = (props: Props) => {
    const {t} = useTranslation();
    const dispatch = useDispatch();
    const {historyPush, restartProcess} = useNavigation();
    const firstAlertTime = 300;
    const secondAlertTime = 15;

    const onFirstAlert = () => {
        toast.info(Dates.time(firstAlertTime) + " " + t("seconds left!") + " " + t("Hurry up or you will miss this timeslot"));
    };

    const onSecondAlert = () => {
        toast.info(Dates.time(secondAlertTime) + " " + t("seconds left!") + " " + t("Your reservation will be cancelled"));
    };

    const onTimeout = () => {
        dispatch(
            BookingAction.setFilter({
                adults: 0,
                children: 0,
                date: Dates.ymd(new Date()),
                activity_ids: [],
                groupType: [],
                hours_count: 0,
            })
        );
        dispatch(BookingAction.clearBookingProcess());
        //dispatch release slot
        dispatch(VoucherAction.resetVouchers());
        dispatch<ThunkAction<any, any, any, any>>(BookingAction.initCart()).then(r => {
            console.log("no time left");
            toast.warning("Sorry, no time left.");
            restartProcess(true);
        });
    };

    const countDown = useCountdown({onFirstAlert, onSecondAlert, onTimeout, firstAlertTime, secondAlertTime});
    const [flashing, setFlashing] = useState(false);

    useEffect(() => {
        if (countDown.timeLeft?.difference === 10) {
            setFlashing(true);
        }

        const timer = setTimeout(() => {
            setFlashing(false);
        }, 10 * 1000);

        return () => {
            clearTimeout(timer);
        };
    }, [countDown.timeLeft.difference]);

    if (!countDown.timeLeft) {
        return <Loader />;
    }

    return (
        <div className="CountdownTimer flex flex-row items-center justify-center text-sm">
            <div className="CountdownTimerInfo mx-2">{t("Time to finish reservation")}</div>
            <div className={"CountdownTimerTag flex flex-row grow items-center px-1 py-1 bg-gray-300 text-gray-900 rounded-md max-w-[100px]"}>
                <ClockIcon className={"w-5 h-5"} />
                <div className="grid grid-cols-[22px,6px,22px] justify-center items-center w-full text-center font-medium">
                    <span className={clsx(flashing ? "text-red-500 animate-pulse" : "text-gray-800", "transform-gpu text-right")}>
                        {padMinutes(countDown.timeLeft.minutes)}
                    </span>
                    <div className={"text-center"}>:</div>
                    <span className={clsx(flashing ? "text-red-500 animate-pulse" : "text-gray-800", "transform-gpu text-left")}>
                        {padMinutes(countDown.timeLeft.seconds)}
                    </span>
                </div>
            </div>
        </div>
    );
};

export default withRouter(CountdownTimer);
