import React from "react";
import {NumberedListIcon} from "@heroicons/react/24/solid";
import clsx from "clsx";
import CardComponent from "pages/Front/Booking/components/CardComponent";
import {useTranslation} from "react-i18next";
import {ReservationNote} from "types/entities/ReservationNote";
import BookingSellerNoteItem
    from "pages/Front/Booking/Seller/BookingSellerReservationDetail/components/BookingSellerNoteItem";
import {Reservation} from "types/entities/Reservation";
import ModalScrollableClose from "components/ModalScrollableClose";

interface BookingSellerNotesAllModalProps {
    reservation: Reservation;
}

const BookingSellerNotesAllModal = (props: BookingSellerNotesAllModalProps) => {
    const {t} = useTranslation();

    const replyNote = (reservationNote: ReservationNote) => {
        if (reservationNote.replied_note_id === null && !props.reservation?.reservationNotes) {
            return null;
        }
        return props.reservation.reservationNotes && props.reservation.reservationNotes.find(note => note.id === reservationNote.replied_note_id);
    };

    return (
        <CardComponent className={clsx(
            "BookingSellerNotesAllModal w-full md:min-w-[500px] max-w-[900px] !p-0 border border-gray-200 relative overflow-hidden h-auto max-h-full flex flex-col",
        )}>
            <div
                className={
                    "top-0 z-30 sticky flex flex-row justify-between rounded-tl-lg rounded-tr-lg w-full px-3 py-3 font-bold border-b-2 border-gray-200 bg-gray-50 text-gray-900 drop-shadow-sm"
                }
            >
                <div className={"flex flex-row items-center"}>
                    <NumberedListIcon className={clsx("w-5 h-5 mr-1 text-gray-600/70 drop-shadow-xl")} />
                    <h4 className={"font-medium text-gray-700"}>{t("All notes")}</h4>
                </div>
                <ModalScrollableClose />
            </div>
            <div className={"flex w-full flex-col px-3 pt-3 pb-4 bg-gray-50 overflow-hidden h-auto rounded-bl-lg rounded-br-lg"}>
                {(!props.reservation?.reservationNotes || (props.reservation?.reservationNotes && props.reservation.reservationNotes.length === 0)) && (
                    <div className={"text-center"}>{t("No data")}</div>
                )}
                {props.reservation?.reservationNotes && props.reservation.reservationNotes.length > 0 && (
                    <div className={"flex overflow-y-auto h-full"}>
                        <ul className={"w-full py-0"} role="list">
                            {props.reservation.reservationNotes
                                .sort((a, b) => (a.created_at < b.created_at ? 1 : -1))
                                .map((reservationNote, index, array) => {
                                        return (
                                            <BookingSellerNoteItem
                                                key={index}
                                                replyNote={replyNote(reservationNote)}
                                                reservation={props.reservation}
                                                reservationNote={reservationNote}
                                            />
                                        )
                                    }
                                )}
                        </ul>
                    </div>
                )}
            </div>
        </CardComponent>
    );
};

export default BookingSellerNotesAllModal;