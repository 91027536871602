import React, {Fragment, useEffect, useRef, useState} from "react";
import {PhoneCountry} from "utils/countryUtils";
import T from "components/T";
import clsx from "clsx";
import {CheckIcon, ChevronDownIcon} from "@heroicons/react/24/solid";
import { Listbox } from "@headlessui/react";
import parsePhoneNumber from "libphonenumber-js";
import {useTranslation} from "react-i18next";
import CompanyFormLabel from "pages/Front/Booking/components/CompanyForm/CompanyFormLabel";

interface CompanyFormPhoneWithCountrySelectCountryComponentProps {
    countryCode?: string;
}
const CompanyFormPhoneWithCountrySelectCountryComponent = (props: CompanyFormPhoneWithCountrySelectCountryComponentProps) => {
    const [countryFlag, setCountryFlag] = useState(null);

    useEffect(() => {
        const loadCountryFlag = async () => {
            try {
                const countryFlagModule = await import(`components/CountryIcons/3x2/${props.countryCode}.svg`);
                setCountryFlag(countryFlagModule.default);
            } catch (error) {
                console.error(`Error loading country flag for ${props.countryCode}:`, error);
                // Handle error (e.g., display a placeholder flag)
            }
        };

        if (props.countryCode) {
            loadCountryFlag().then(r => {
            });
        }

        // Clean up on unmount
        return () => {
            setCountryFlag(null);
        };
    }, [props.countryCode]);

    // return <div className={"CountryFlag min-w-[20px] w-[20px] min-h-[13px] h-[13px] border border-gray-200"}>{countryFlag && <img alt={`${props.countryCode} flag`} src={countryFlag} />}</div>;
    return <div className={"CountryFlag min-w-[26px] w-[26px] min-h-[18px] h-[18px] flex flex-row items-center border border-gray-200"}>{countryFlag && <img alt={`${props.countryCode} flag`} src={countryFlag} />}</div>;
};

interface CompanyFormPhoneWithCountrySelectProps {
    options: PhoneCountry[];
    defaultCountry: any;
    defaultValue: any;
    register: any;
    name: string;
    label: string;
    className?: string;
    classNameInput?: string;
    classNameLabel?: string;
    required?: boolean;
    errors?: any;
    disabled?: boolean;
    // onFocus?: (element: FocusEvent<HTMLInputElement>) => void;
    onFocus?: (element: any) => void;
    setValue?: any;
    // keyboard?: any;
    setSelectedCountry: (country: PhoneCountry) => void;
}

const CompanyFormPhoneWithCountrySelect = (props: CompanyFormPhoneWithCountrySelectProps) => {
    const {t} = useTranslation();
    const [selectedCountry, setSelectedCountry] = useState<PhoneCountry | null>(null);
    const selectedOptionRef = useRef<HTMLLIElement>(null);
    const searchCountryByCode = (code: string): PhoneCountry => {
        const countryIndex = props.options.findIndex(country => country.textCode === code);
        return props.options[countryIndex];
    };

    const setSelectedCountryHandler = (country: PhoneCountry) => {
        setSelectedCountry(country);
        props.setSelectedCountry(country);
        props.setValue(country.code);
    };

    useEffect(() => {
        if (props.defaultValue) {
            const phoneNumber = parsePhoneNumber(props.defaultValue);
            const defaultCountry = searchCountryByCode(phoneNumber?.country ? phoneNumber.country : props.defaultCountry.textCode);
            setSelectedCountry(defaultCountry);
            props.setSelectedCountry(defaultCountry);
        }
        if (!props.defaultValue) {
            const defaultCountry = searchCountryByCode(props.defaultCountry);
            setSelectedCountry(defaultCountry);
            props.setValue(defaultCountry.code);
        }
    }, [props.defaultValue]);

    return (
        <div className={"BookingLoginReservationDetailPhoneWithCountrySelect w-full"}>
            <CompanyFormLabel
                className={props.classNameLabel}
                errors={props.errors}
                label={props.label}
                name={props.name}
                required={props.required}
            />

            <div className={"flex flex-row space-x-2 relative flex-1"}>
                <Listbox disabled={props.disabled} value={selectedCountry} onChange={setSelectedCountryHandler}>
                    {({open}) => (
                        <div className="">
                            <Listbox.Button
                                className={clsx(
                                    // "relative h-full ui-open:ring-black font-exo items-center flex justify-between w-full text-left rounded-[1rem] border-0 pb-[1.1rem] pt-[1.85rem] pl-[1.5rem] pr-[0.75rem] shadow-sm ring-2 ring-kiosk-lightGray ring-inset focus:ring-2 focus:ring-black focus:outline-0 text-[2.0rem] placeholder:text-[2.0rem]",
                                    // "relative h-full flex items-center justify-between w-full px-1 py-1 rounded-md border-gray-200 border",
                                    "relative cursor-pointer rounded-md bg-white text-sm pl-3 text-left text-gray-900 shadow-sm ring-1 ring-inset ring-gray-800 focus:outline-none focus:ring-1 focus:ring-cta-800 sm:text-sm sm:leading-6",
                                    "h-full flex items-center justify-between w-full pr-2",
                                    // "relative h-full flex items-center justify-between w-full pl-2 pr-2 py-1 rounded-md border-gray-200 border",
                                    props.disabled ? selectedCountry ? "bg-gray-100 text-gray-600 pointer-events-none" : "bg-gray-100 text-gray-400" : selectedCountry ? "bg-white text-black" : "bg-white text-gray-400",
                                    selectedCountry ? "text-black border-black" : "text-gray-400",
                                    (props.errors?.[props.name] && !open && !selectedCountry) && "border-red-500",
                                )}
                            >
                                <CompanyFormPhoneWithCountrySelectCountryComponent countryCode={selectedCountry?.textCode} />
                                <span className="pointer-events-none pl-2">
                                    <ChevronDownIcon className={"w-3.5 h-3.5"} />
                                </span>
                            </Listbox.Button>
                            <Listbox.Options
                                className={"absolute drop-shadow shadow-sm bottom-[40px] sm:bottom-auto z-30 max-h-[15rem] h-auto w-full overflow-auto rounded-md bg-white py-1 text-base"}
                                unmount={false}
                                onLoad={() => {
                                    if (selectedCountry && selectedOptionRef.current) {
                                        selectedOptionRef.current.scrollIntoView({
                                            behavior: "smooth",
                                            block: "nearest",
                                        });
                                    }
                                }}
                            >
                                {props.options.map(option => (
                                    <Listbox.Option
                                        key={option.textCode}
                                        as={Fragment}
                                        value={option}
                                    >
                                        {({selected, active}) => (
                                            <li
                                                ref={selected ? selectedOptionRef : null}
                                                className={clsx(
                                                    // "relative flex flex-row cursor-pointer select-none py-1 pr-0 text-sm",
                                                    // active || selected ? "bg-gray-200/80 text-black" : "",
                                                    "relative flex flex-row cursor-pointer select-none py-2 pr-0 text-sm",
                                                    active || selected ? "bg-gray-200/80 text-black" : "",
                                                )}
                                            >
                                                {selected && (
                                                    <span className={"w-9 flex items-center"}>
                                                        {/*<CheckIcon className={"w-5 h-5 ml-1.5"} />*/}
                                                        <CheckIcon className={"w-[18px] h-[18px] grow ml-0"} />
                                                    </span>
                                                )}
                                                <div
                                                    className={clsx("flex items-center truncate", !selected && "pl-9")}>
                                                    <CompanyFormPhoneWithCountrySelectCountryComponent countryCode={option.textCode} />
                                                    <span
                                                        className={clsx("ml-1 block truncate")}>
                                                        {option.code} {option.name}
                                                    </span>
                                                </div>
                                            </li>
                                        )}
                                    </Listbox.Option>
                                ))}
                            </Listbox.Options>
                        </div>
                    )}
                </Listbox>
                <div className={"relative grow flex flex-row"}>
                    <input
                        {...props.register(props.name)}
                        className={clsx(
                            "py-3 px-3 w-full",
                            "border rounded-md shadow-xs focus:outline-none active:ring-cta-800 active:ring-1 active:ring-offset-1 focus:border-cta-800 checked:bg-cta-800 sm:text-sm",
                            props.classNameInput,
                            props.errors?.[props.name] ? "ring-1 ring-red-500 border-red-500" : "border-gray-800",
                            props.disabled ? "grayscale bg-gray-400" : "",
                        )}
                        defaultValue={props.defaultValue}
                        disabled={props.disabled}
                        name={props.name}
                        required={props.required}
                        type={"tel"}
                        onFocus={props.onFocus}
                    />
                </div>
            </div>
            {props.errors?.[props.name]?.message && (
                <div className={"text-red-500 text-sm mt-1 flex flex-row items-center"}>
                    <T>{props.errors?.[props.name]?.message}</T>
                </div>
            )}
        </div>
    );
};

export default CompanyFormPhoneWithCountrySelect;