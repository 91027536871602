import React from "react";
import clsx from "clsx";
import {CheckIcon} from "@heroicons/react/24/solid";
import {useTranslation} from "react-i18next";

interface SellerButtonSwitchProps {
    isActive?: boolean;
    title: string;
    name: string;
    onClick: (name: string) => void;
    position?: "left" | "right" | null;
}

const SellerButtonSwitch = (props: SellerButtonSwitchProps) => {
    const {t} = useTranslation();
    return (
        <button
            className={clsx(
                "border flex items-center px-4 sm:px-3 py-3 space-x-2 font-medium text-base sm:text-lg sm:leading-5",
                props.isActive ? "bg-arbo-orange text-white border-arbo-orange" : "bg-white",
                props.position === "left" && "rounded-tl-md rounded-bl-md",
                props.position === "right" && "rounded-tr-md rounded-br-md",
                !props.position && "rounded-md"
            )}
            type={"button"}
            onClick={() => props.onClick(props.name)}
        >
            <span className={clsx("flex items-center justify-center border bg-white rounded-full w-5 h-5", props.isActive && "border-arbo-orange")}>
                {props.isActive && <CheckIcon className={"h-4 w-4 text-arbo-orange"} />}
            </span>
            <span>{props.title}</span>
        </button>
    );
};

export default SellerButtonSwitch;
