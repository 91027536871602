import React, { useMemo } from "react";
import PackageUpsellOptionItem from "pages/Front/Booking/components/PackageUpsell/PackageUpsellOptionItem";
import {
    getPackageItemsWithGroupOptions,
    getSelectedUpsell,
    packageItemUpsellPersonType,
    sortPackageItemsByPosition,
} from "utils/utility";
import {useTranslation} from "react-i18next";
import {Package} from "types/entities/Package";
import {BookingProcess} from "types/app";
import BookingPackageUpsellsHeader from "pages/Front/Booking/components/PackageUpsell/BookingPackageUpsellsHeader";
import {useTypedSelector} from "helpers/reducers";
import { PackageItem } from "types/entities/PackageItem";
import BookingAction from "store/booking/BookingAction";
import {useDispatch} from "useDispatch";

interface Props {
    Package: Package;
    bookingProcess: BookingProcess;
    uniqueGroupOptions: number[];
}

const BookingPackageUpsellWithGroup = (props: Props) => {
    const {t} = useTranslation();
    const dispatch = useDispatch();
    const packageItemsWithGroupOptions = getPackageItemsWithGroupOptions(props.Package);
    const bookingProcess = useTypedSelector(state => state.bookingState.bookingProcess);
    const bookingFilter = bookingProcess.filter;
    const bookingPackage = bookingProcess.package;
    const persons = +bookingFilter.adults + +bookingFilter.children;
    const bookingProcessContainsUpsells = (uniqueOption: number): boolean => {
        const uniqueOptionIndex = props.bookingProcess.upsell.findIndex(upsellPackageItem => upsellPackageItem.packageItem.extra.group === uniqueOption);
        return uniqueOptionIndex !== -1;
    };

    const findGroupedPackageItemsByArticleGroupId = (data: PackageItem[]): object => {
        return data.reduce((result, currentValue) => {
            if (!currentValue?.extra?.article_group_id) {
                return result;
            }
            (result[currentValue?.extra["article_group_id"]] = result[currentValue?.extra["article_group_id"]] || []).push(currentValue);
            return result;
        }, {});
        // return Object.values(newData);
    }

    const getPackageItemsList = (uniqueOption: number): PackageItem[] => useMemo(() => {
        return sortPackageItemsByPosition(packageItemsWithGroupOptions)
          .filter(packageItem => {
              return packageItemUpsellPersonType(bookingFilter, packageItem.person_type);
          })
          .filter(packageItem => packageItem.extra.group === uniqueOption)
          .filter(packageItem => {
              if (packageItem.minimum_persons_count === 0) return true;
              if (packageItem.minimum_persons_count > 0) {
                  if(bookingPackage && bookingPackage.rewrite_url === "exclusive") {
                      return true;
                  }
                  return persons >= packageItem.minimum_persons_count;
              }
          });
    }, [bookingFilter, uniqueOption]);

    const setPackage = (firstPackageFromGrouped: PackageItem, maxPersonCount: number) => {
            dispatch(
                BookingAction.pushUpsell({
                    packageItem: firstPackageFromGrouped,
                    upsell_option: firstPackageFromGrouped.extra.upsell_option,
                    count: Number(maxPersonCount),
                })
            );
    }

    return (
        <div className={"uniqueGroupOptions space-y-8"}>
            {props.uniqueGroupOptions.sort((a, b) => a > b ? 1 : -1).map(uniqueOption => {
                const contained = bookingProcessContainsUpsells(uniqueOption);
                const packageItemsList = getPackageItemsList(uniqueOption);
                const groupedPackageItemsByArticleGroupId = findGroupedPackageItemsByArticleGroupId(packageItemsList);
                const allGroupedPackages = Object.values(groupedPackageItemsByArticleGroupId);
                const selectedGroupedPackage = allGroupedPackages.length > 0 ? allGroupedPackages[0].filter(packageItem => {
                    return getSelectedUpsell(bookingProcess.upsell, packageItem) !== undefined
                }) : [];
                return (
                    <div key={uniqueOption} className={"UniqueOption "}>
                        {packageItemsList.length > 0 && (
                            <>
                                <BookingPackageUpsellsHeader title={t("Please select one option")} />
                                <div className={"packageItemsWithGroupOptions space-y-4"}>
                                    {packageItemsList
                                        .map((packageItem, index) => {
                                            return (
                                                <PackageUpsellOptionItem
                                                    key={packageItem.id}
                                                    bookingProcess={props.bookingProcess}
                                                    contained={contained}
                                                    groupedPackageItems={packageItem?.extra?.article_group_id ? groupedPackageItemsByArticleGroupId : null}
                                                    packageItem={packageItem}
                                                    selectedGroupedPackage={selectedGroupedPackage}
                                                    selectedPackage={props.Package}
                                                    zIndex={index}
                                                />
                                            );
                                        })}
                                </div>
                            </>
                        )}
                    </div>
                );
            })}
        </div>
    );
};

export default BookingPackageUpsellWithGroup;
