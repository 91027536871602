import clsx from "clsx";
import React from "react";

interface NumberInputProps {
  className?: string;
  children: React.ReactNode | React.ReactNode[];
}

export const NumberInput = (props: NumberInputProps) => {
  return (
    <div className="flex flex-row items-center h-full justify-center relative">
      <div
        className={clsx(
          props.className ? props.className : "text-8xl w-full h-full",
          "relative object-fill border border-gray-400 transform-gpu rounded-md text-center justify-items-center flex justify-center items-center"
        )}
      >
        {props.children}
      </div>
    </div>
  );
};
