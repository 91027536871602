import React from "react";
import CardLabel from "pages/Front/Booking/components/BookingCustomer/CardLabel";
import {PackagesCartItem} from "types/entities/PackagesCartItem";
import {translate} from "utils/utility";
import UsersIcon from "@heroicons/react/24/outline/UsersIcon";
import CardComponent from "pages/Front/Booking/components/CardComponent";
import {useTranslation} from "react-i18next";

const BookedExtraItems = (props: {packagesCartItemsOutstanding: PackagesCartItem[]}) => {
    const {t} = useTranslation();

    return (
        <CardComponent>
            <CardLabel title={t("Extra items with your activity")} />
            <div className={"flex flex-col items-center justify-center divide-y space-y-2 divide-gray-600"}>
                {props.packagesCartItemsOutstanding.map((packagesCartItem: PackagesCartItem, key: number) => {
                    return (
                        <div key={key} className={"PackageCartItem  w-full flex flex-row pt-2 space-x-2"}>
                            <div className={"flex-grow"}>{packagesCartItem.packageItem && translate(packagesCartItem.packageItem.name)}</div>
                            <b className={"mx-4 flex flex-row items-center space-x-1"}>
                                <span>{packagesCartItem.drivers_count_adult + packagesCartItem.drivers_count_child}</span>
                                <UsersIcon className={"w-4 h-4 text-gray-800"} />
                            </b>
                        </div>
                    );
                })}
            </div>
        </CardComponent>
    );
};

export default BookedExtraItems;
