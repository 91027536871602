import React from "react";
import clsx from "clsx";
import BookingCard from "pages/Front/Booking/components/BookingCard";
import BookingCardTitle from "pages/Front/Booking/components/BookingCardTitle";
import {useTranslation} from "react-i18next";
import {PackagesCartItemVoucher} from "types/entities/PackagesCartItemVoucher";
import {useVoucherTypeByArticle} from "utils/voucherUtils";
import TableHead from "pages/Front/components/Table/TableHead";
import TableHeadColumn from "pages/Front/components/Table/TableHeadColumn";
import TableHeadTitle from "pages/Front/components/Table/TableHeadTitle";
import {BanknotesIcon, RectangleStackIcon} from "@heroicons/react/24/outline";
import Table from "pages/Front/components/Table/Table";
import TableBody from "pages/Front/components/Table/TableBody";
import TableRow from "pages/Front/components/Table/TableRow";
import TableColumn from "pages/Front/components/Table/TableColumn";
import {QrCodeIcon} from "@heroicons/react/24/solid";

interface BookingSellerRecapitulationPackageCartItemVouchersTableProps {
    packagesCartItemVouchers: PackagesCartItemVoucher[];
}

const BookingSellerRecapitulationPackageCartItemVouchersTable = (props: BookingSellerRecapitulationPackageCartItemVouchersTableProps) => {
    const {t} = useTranslation();
    const {getVoucherType} = useVoucherTypeByArticle();
    return (
        <BookingCard className={clsx("ReservationsForDate !px-0 !py-0")}>
            <BookingCardTitle className={"px-3 py-2.5 xl:px-3 xl:py-3"} title={t("Vouchers")} />
            <Table className={"border-t"} responsive={true}>
                <TableHead>
                    <TableHeadColumn>
                        <TableHeadTitle>
                            <QrCodeIcon className={"w-4 h-4 mr-1.5"} />
                            <span>{t("Code")}</span>
                        </TableHeadTitle>
                    </TableHeadColumn>
                    <TableHeadColumn>
                        <TableHeadTitle>
                            <RectangleStackIcon className={"w-4 h-4 mr-1.5"} />
                            <span>{t("Type")}</span>
                        </TableHeadTitle>
                    </TableHeadColumn>
                    <TableHeadColumn>
                        <TableHeadTitle className={"justify-end"}>
                            <BanknotesIcon className={"w-4 h-4 mr-1.5"} />
                            <span>{t("Price")}</span>
                        </TableHeadTitle>
                    </TableHeadColumn>
                </TableHead>
                <TableBody>
                    {props.packagesCartItemVouchers.length > 0 && props.packagesCartItemVouchers.map((packagesCartItemVoucher, index) => {
                        return (
                            <TableRow key={index}>
                                <TableColumn className={"font-medium"}>
                                    {packagesCartItemVoucher.voucher.code}
                                </TableColumn>
                                <TableColumn>
                                    {getVoucherType(packagesCartItemVoucher.voucher).label}
                                </TableColumn>
                                <TableColumn className={"text-right"}>
                                    - {getVoucherType(packagesCartItemVoucher.voucher).valueFormatted}
                                </TableColumn>
                            </TableRow>
                        )
                    })}
                    {props.packagesCartItemVouchers.length === 0 && (
                        <TableRow>
                            <td className={"px-3 py-2 text-center"} colSpan={3}>{t("No items")}</td>
                        </TableRow>
                    )}
                </TableBody>
            </Table>
        </BookingCard>
    );
};

export default BookingSellerRecapitulationPackageCartItemVouchersTable;