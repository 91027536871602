import React from "react";
import {GroupedPackageItem} from "types/entities/GroupedPackageItem";
import {useTranslation} from "react-i18next";
import GroupedPackageUpsellItemItem from "pages/Front/Booking/components/PackageUpsell/GroupedPackageUpsellItemItem";
import {ServerImage} from "components/ServerImage";
import {translate} from "utils/utility";

interface GroupedPackageUpsellItemProps {
    item: GroupedPackageItem;
}

const GroupedPackageUpsellItem = (props: GroupedPackageUpsellItemProps) => {
    const {t} = useTranslation();

    return (
        <div className={"GroupedPackageUpsellItem w-full flex flex-col bg-white rounded-lg shadow"}>
            <div className={"w-full flex flex-col md:flex-row md:items-stretch"}>
                <div className={"flex flex-row w-full md:w-auto md:self-stretch md:flex-1"}>
                    <div className={"grow flex flex-col md:items-stretch w-full max-w-full md:w-[16rem] md:max-w-[16rem] h-full"}>
                        {props.item.image && props.item.image !== "" && (
                            <div className={"itemImage flex h-full overflow-hidden aspect-[64/56] sm:max-h-[250px] md:max-h-full md:max-w-[16rem] w-full grow rounded-t-lg md:rounded-tr-none"}>
                                <ServerImage
                                    alt={"Package Item Image"}
                                    className={"w-full md:max-h-auto object-cover object-center"}
                                    src={props.item.image}
                                />
                            </div>
                        )}
                    </div>
                </div>
                <div className={"flex flex-col xl:flex-row items-center justify-between w-full divide-y xl:divide-y-0 xl:divide-x"}>
                    {props.item.items.map((packageItem, index) => (
                        <GroupedPackageUpsellItemItem key={index} packageItem={packageItem} />
                    ))}
                </div>
            </div>
            <div className={"text-xs sm:text-sm text-black border-t py-2 px-2 md:py-3 md:px-3 lg:py-4 lg:px-4 flex-grow flex flex-col justify-center items-start"}>
                <h4 className={"font-bold text-left text-sm sm:text-base space-x-1.5"}><span>{t("Description")}:</span><span className={"text-xs sm:text-sm font-medium"}>({t("Choose one or more items")})</span></h4>
                {props.item.description_html && (
                    <div dangerouslySetInnerHTML={{__html: translate(props.item.description_html)}} />
                )}
                {!props.item.description_html && (
                    <div>{translate(props.item.description)}</div>
                )}
            </div>
        </div>
    );
};

export default GroupedPackageUpsellItem;