import React from "react";
import clsx from "clsx";

interface TableBodyProps {
    children: React.ReactNode | React.ReactNode[];
    className?: string;
}

const TableBody = (props: TableBodyProps) => {
    return (
        <tbody className={clsx("TableBody", props.className)}>
            {props.children}
        </tbody>
    );
};

export default TableBody;