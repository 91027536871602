import React from "react";
import clsx from "clsx";

interface TableHeadTitleProps {
    className?: string;
    children: React.ReactNode | React.ReactNode[];
}

const TableHeadTitle = (props: TableHeadTitleProps) => {
    return (
        <div className={clsx("flex flex-row items-center", props.className)}>
            {props.children}
        </div>
    );
};

export default TableHeadTitle;