import React from "react";
import clsx from "clsx";

interface TableColumnResponsiveProps {
    children: React.ReactNode | React.ReactNode[];
    className?: string;
    label?: string
    classNameLabel?: string;
    classNameValue?: string;
}

const TableColumnResponsive = (props: TableColumnResponsiveProps) => {
    return (
        <td className={clsx("TableColumnResponsive py-2 px-1.5 first:!pl-3 last:!pr-3", props.className)}>
            {props.label &&
                <div className={clsx("flex md:hidden font-bold w-full", props.classNameLabel)}>{props.label}</div>
            }
            <div className={clsx("flex flex-row w-full", props.classNameValue)}>
                {props.children}
            </div>
        </td>
    );
};

export default TableColumnResponsive;