import React from "react";
import clsx from "clsx";

interface BookingLoginTableHeadColumnProps {
    className?: string;
    children: React.ReactNode | React.ReactNode[];
}

const BookingLoginTableHeadColumn = (props: BookingLoginTableHeadColumnProps) => {
    return (
        <div className={clsx("BookingLoginTableRowColumn md:col-span-1 inline-flex py-1.5 px-3", props.className)}>
            {props.children}
        </div>
    );
};

export default BookingLoginTableHeadColumn;