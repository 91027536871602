import React from "react";
import {ReservationJournal} from "types/entities/ReservationJournal";
import Dates from "utils/Dates";
import clsx from "clsx";
import CustomerProfilePicture
    from "pages/Front/Booking/Seller/BookingSellerReservationDetail/components/CustomerProfilePicture";

interface BookingSellerJournalItemProps {
    reservationJournal: ReservationJournal;
    lastItem?: boolean;
}

const BookingSellerJournalItem = (props: BookingSellerJournalItemProps) => {
    return (
        <li className="BookingSellerJournalItem relative flex gap-x-1 group">
            <div
                className={clsx("absolute left-0 top-0 flex w-8 justify-center", props.lastItem ? "h-6" : "-bottom-6")}>
                <div className="w-px bg-gray-200"></div>
            </div>
            <div className="relative flex w-11 h-8 flex-none items-center justify-center bg-white">
                <CustomerProfilePicture driver={props.reservationJournal.driver} />
            </div>
            <div>
                <div
                    className="font-bold">{props.reservationJournal.driver && props.reservationJournal.driver.name}</div>
                <time
                    className={"flex-none py-0.5 leading-5 text-sm text-gray-800"}>{Dates.dateTime(props.reservationJournal.created_at)}</time>
                <div className="flex-auto leading-5 mt-2 bg-gray-100 rounded py-1 px-2 mb-6 group-last:!mb-0">
                    {props.reservationJournal.text}
                </div>
            </div>
        </li>
    );
};

export default BookingSellerJournalItem;