import AlertConfirmation from "pages/Front/Registration/components/AlertConfirmation";
import React from "react";
import {Reservation} from "types/entities/Reservation";
import {ReservationNote} from "types/entities/ReservationNote";
import {useDispatch} from "useDispatch";
import {useTranslation} from "react-i18next";
import {
    ArrowUturnLeftIcon,
    ArrowUturnRightIcon,
    PaperAirplaneIcon,
    TrashIcon,
    UsersIcon,
} from "@heroicons/react/24/solid";
import CustomerProfilePicture
    from "pages/Front/Booking/Seller/BookingSellerReservationDetail/components/CustomerProfilePicture";
import Dates from "utils/Dates";
import clsx from "clsx";
import {driverName} from "utils/utility";
import {ModalSize} from "pages/Front/components/Popup";
import {openModal} from "helpers/actions";
import {toast} from "react-toastify";
import BookingAction from "store/booking/BookingAction";
import BookingSellerNotesAddFormModal
    from "pages/Front/Booking/Seller/BookingSellerReservationDetail/components/BookingSellerNotesAddFormModal";

interface BookingSellerNoteItemProps {
    replyNote?: ReservationNote | null;
    reservation: Reservation;
    reservationNote: ReservationNote;
}

const BookingSellerNoteItem = (props: BookingSellerNoteItemProps) => {
    const {t} = useTranslation();
    const dispatch = useDispatch();

    const replyToReservationHandler = () => {
        AlertConfirmation({
            message: props.reservationNote.creator_id !== null ? t("Are you sure?") : t("Are you sure?"),
            onClick: () => {
                if (props.reservationNote.creator_id !== null) {
                    dispatch(BookingAction.resendReservationNote(props.reservation, props.reservationNote.id)).then(r => {
                        if (r?.reservationNote) {
                            toast.success(t("Note sent successfully"));
                        }
                    });
                } else {
                    dispatch(
                        openModal(<BookingSellerNotesAddFormModal reservation={props.reservation} replied_note_id={props.reservationNote.id} />, {
                            modalSize: ModalSize.Medium,
                        })
                    );
                }
            },
            title:
                props.reservationNote.creator_id !== null
                    ? t("You are going to send this note to the customer.")
                    : t("You are going to send this note to the customer."),
        });
    };

    const deleteReservationHandler = () => {
        AlertConfirmation({
            message: t("Are you sure?"),
            onClick: () => {
                dispatch(BookingAction.deleteReservationNote(props.reservationNote.id)).then(r => {
                    toast.warning(t("Note deleted"));
                });
            },
            title: t("You are going to delete this note."),
        });
    };

    return (
        <li className="BookingSellerNoteItem relative pb-12 last:pb-4" key={props.reservationNote.id}>
            <span aria-hidden="true" className="absolute top-0 left-5 -ml-px h-full w-0.5 bg-gray-200" />
            <div className="relative flex items-start space-x-3">
                <div className="relative">
                    <div className={"w-8 h-8"}>
                        {props.reservationNote.creator ? (
                            <CustomerProfilePicture driver={props.reservationNote.creator} />
                        ) : (
                            <div className={"bg-gray-300 w-8 h-8 flex items-center justify-center rounded"}>
                                <UsersIcon className={"w-5 h-5"} />
                            </div>
                        )}
                    </div>
                </div>
                <div className="min-w-0 flex-1">
                    <div className={"mb-2"}>
                        <div className="">
                            <div className="font-medium text-gray-900">
                                {props.reservationNote.creator ? driverName(props.reservationNote.creator) : t("Customer")}
                            </div>
                        </div>
                        <p className="mt-0.5 text-sm text-gray-800 flex flex-row justify-between">
                            <span>{Dates.dateTime(props.reservationNote.created_at)}</span>
                            {props.reservationNote.is_public == true && <span>{t("Visible to customer")}</span>}
                        </p>
                    </div>
                    {props.replyNote && (
                        <div className={"relative"}>
                            <p className={"-ml-2 mr-2 rounded px-2 pt-1 pb-3 -mb-2 bg-gray-500 text-gray-800/90 whitespace-pre-wrap"}>{props.replyNote.note}</p>
                            <div className={"absolute -bottom-1 -left-4 text-black/90 border border-gray-500 bg-white rounded p-1 z-10"}>
                                <ArrowUturnRightIcon className={"w-3 h-3"} />
                            </div>
                        </div>
                    )}
                    <div className={clsx("relative bg-gray-100 rounded py-1 px-2", props.replyNote && "")}>
                        <p className={"pr-4 whitespace-pre-wrap"}>{props.reservationNote.note}</p>
                        {/*<button*/}
                        {/*    className={clsx(*/}
                        {/*        "absolute hover:bg-green-500 text-green-500 hover:text-white  -bottom-4 right-9 w-6 h-6 flex flex-col justify-center items-center p-1 border border-gray-300 bg-white rounded text-gray-500"*/}
                        {/*    )}*/}
                        {/*    type={"button"}*/}
                        {/*    onClick={() => copyToClipboard(props.reservationNote.note)}*/}
                        {/*>*/}
                        {/*    <ClipboardIcon />*/}
                        {/*</button>*/}
                        <button
                            className={clsx(
                                "absolute hover:bg-red-500 text-red-500 hover:text-white -bottom-4 right-16 w-6 h-6 flex flex-col justify-center items-center p-1 border border-gray-300 bg-white rounded text-gray-500"
                            )}
                            type={"button"}
                            onClick={deleteReservationHandler}
                        >
                            <TrashIcon />
                        </button>
                        <button
                            className={clsx(
                                "absolute -bottom-4 right-1 w-6 h-6 flex flex-col justify-center items-center p-1 border border-gray-200 rounded text-white bg-white duration-150",
                                props.reservationNote.creator_id === null ? "!bg-green-400 hover:!bg-green-600" : "!bg-blue-400 hover:!bg-blue-600",
                                !!props.replyNote && "!bg-gray-700 hover:!bg-gray-700 cursor-not-allowed"
                            )}
                            disabled={!!props.replyNote}
                            type={"button"}
                            onClick={replyToReservationHandler}
                        >
                            {props.reservationNote.creator_id === null && <ArrowUturnLeftIcon />}
                            {props.reservationNote.creator_id !== null && <PaperAirplaneIcon />}
                        </button>
                    </div>
                    {props.reservationNote.sent_at && (
                        <p className={"text-gray-700 text-xs ml-2 mt-1 space-x-1"}>
                            <span>{t("This note has been sent at")}</span>
                            <span className={"font-bold"}>{Dates.dateTime(props.reservationNote.sent_at)}</span>
                        </p>
                    )}
                </div>
            </div>
        </li>
    );
};

export default BookingSellerNoteItem;