import React from "react";
import clsx from "clsx";
import {flexRender} from "@tanstack/react-table";
import {BarsArrowDownIcon, BarsArrowUpIcon} from "@heroicons/react/24/solid";

interface TableHeadColumnTitleProps {
    className?: string;
    children?: React.ReactNode | React.ReactNode[];
    header: any;
    tableInit: any;
    personsCount?: number;
}

const TableHeadColumnTitle = (props: TableHeadColumnTitleProps) => {
    return (
        <div
            className={clsx(
                "flex flex-row items-center",
                props.className && props.className
            )}
        >
            {flexRender(
                props.header.column.columnDef.header,
                props.header.getContext()
            )}
            {props.header.column.columnDef.enableSorting && (
                <button disabled={props.tableInit.isLoading} type={"button"} onClick={() => props.tableInit.sortChangeHandler(props.header.column.id)}>
                    {{
                        asc: <BarsArrowDownIcon className="h-5 w-5 text-blue-400 ml-2" />,
                        desc: <BarsArrowUpIcon className="h-5 w-5 text-blue-400 ml-2" />,
                    }[props.header.column.getIsSorted() as string] ?? null}
                    {!props.header.column.getIsSorted() &&
                        <BarsArrowUpIcon className="h-5 w-5 text-gray-700 ml-2" />
                    }
                </button>
            )}
            {props.children && props.children}
        </div>
    );
};

export default TableHeadColumnTitle;