export enum BookingSteps {
    Intro = 1,
    Package = 2,
    Upsell = 3,
    Timeslots = 4,
    Customer = 5,
    Recapitulation = 6,
    Payment = 7,
    Final = 8,
}

