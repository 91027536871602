import React from "react";
import PackageUpsellItem from "pages/Front/Booking/components/PackageUpsell/PackageUpsellItem";
import {
    getPackageItemsWithoutGroupOptions,
    packageItemUpsellPersonType,
    sortPackageItemsByPosition,
} from "utils/utility";
import {Package} from "types/entities/Package";
import {useTypedSelector} from "helpers/reducers";
import BookingPackageUpsellsHeader from "pages/Front/Booking/components/PackageUpsell/BookingPackageUpsellsHeader";
import {useTranslation} from "react-i18next";

interface Props {
    Package: Package;
}

const BookingPackageUpsellWithoutGroup = (props: Props) => {
    const packageItemsWithoutGroupOptions = getPackageItemsWithoutGroupOptions(props.Package);
    const bookingProcess = useTypedSelector(state => state.bookingState.bookingProcess);
    const bookingFilter = bookingProcess.filter;
    const persons = +bookingFilter.adults + +bookingFilter.children;

    const {t} = useTranslation();
    const packageItemsList = sortPackageItemsByPosition(packageItemsWithoutGroupOptions)
        .filter(packageItem => {
            return packageItemUpsellPersonType(bookingFilter, packageItem.person_type);
        }).filter(packageItem => {
            if (packageItem.minimum_persons_count === 0) return true;
            if (packageItem.minimum_persons_count > 0) {
                return persons >= packageItem.minimum_persons_count;
            }

        });
    return (
        <div className={"BookingPackageUpsellWithoutGroup"}>
            {packageItemsList.length > 0 && (
                <>
                    <BookingPackageUpsellsHeader title={t("Please select your extras")} />
                    <div className={" space-y-8"}>
                    {packageItemsList
                        .map(packageItem => (
                            <PackageUpsellItem key={packageItem.id} packageItem={packageItem}
                                               selectedPackage={props.Package} />
                        ))}
                    </div>
                </>
            )}
        </div>
    );
};

export default BookingPackageUpsellWithoutGroup;
