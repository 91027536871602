import React, {useEffect, useState} from "react";
import {ShoppingCartItemPaymentType} from "types/enums/ShoppingCartItemPaymentType";
import {useTypedSelector} from "helpers/reducers";
import {ReservationCheckoutPlan} from "types/entities/ReservationCheckoutPlan";
import {useTranslation} from "react-i18next";
import {ArticleGroup} from "types/entities/ArticleGroup";
import Loader from "components/Loader";
import UiTabSwitcher, {UiTabSwitcherItem} from "pages/Front/components/Ui/components/UiTabSwitcher";
import {Switch} from "@headlessui/react";

interface ReservationCheckoutPlanRowProps {
    plan: ReservationCheckoutPlan;
    onChange: (plan: ReservationCheckoutPlan) => void;
}

const ReservationCheckoutPlanRow = (props: ReservationCheckoutPlanRowProps) => {
    const articleGroups = useTypedSelector(state => state.articlesState.articleGroups);
    const [articleGroup, setArticleGroup] = useState<ArticleGroup | null>(null);
    const {t} = useTranslation();
    const [isActive, setIsActive] = useState(props.plan?.is_active ? props.plan.is_active : false);

    const setIsActiveHandler = e => {
        setIsActive(e);
        setValueHandler(props.plan.article_group_id, "is_active", !!e);
    };

    const setActiveTabHandler = (active_tab: string | number) => {
        setValueHandler(props.plan.article_group_id, "payment_type", active_tab);
    };

    const setValueHandler = (id, name, value) => {
        props.plan[name] = value;
        props.onChange(props.plan);
    };

    const itemsForOptions: UiTabSwitcherItem[] = [
        {id: ShoppingCartItemPaymentType.Checkout, name: ShoppingCartItemPaymentType.Checkout},
        {id: ShoppingCartItemPaymentType.Invoice, name: ShoppingCartItemPaymentType.Invoice},
    ];

    useEffect(() => {
        const currentArticleGroup = articleGroups && articleGroups.find(articleGroup => articleGroup.id == props.plan.article_group_id);
        currentArticleGroup && setArticleGroup(currentArticleGroup);
    }, [articleGroups]);

    useEffect(() => {
        setIsActive(props.plan?.is_active ? props.plan.is_active : false);
    }, [props.plan?.is_active]);

    if (!articleGroups || !articleGroup) {
        return <Loader />;
    }
    return (
        <div className={"ReservationCheckoutPlanRow border border-gray-400 p-2 rounded shadow-sm"}>
            <form className={"grid grid-cols-7 gap-x-2"}>
                <div className={"col-span-4 flex items-center"}>
                    {t("Article Group")} {props.plan.article_group_id} | {articleGroup.name}
                </div>
                <div className={"col-span-2 flex items-center"}>
                    <UiTabSwitcher
                        selected={props.plan.payment_type}
                        onClick={(e) => setActiveTabHandler(e)}
                        items={itemsForOptions}
                    />
                    {/*<TabSwitcher*/}
                    {/*    activeTab={props.plan.payment_type}*/}
                    {/*    setActiveTab={setActiveTabHandler}*/}
                    {/*    tabs={[ShoppingCartItemPaymentType.Checkout, ShoppingCartItemPaymentType.Invoice]}*/}
                    {/*/>*/}
                </div>
                <div className={"col-span-1 flex items-center justify-end"}>
                    <Switch
                        checked={isActive}
                        className={`${isActive ? "bg-blue-400" : "bg-gray-500"} relative inline-flex h-6 w-11 items-center rounded-full`}
                        id={"active"}
                        onChange={setIsActiveHandler}
                    >
                        <span className="sr-only">{t("Active")}</span>
                        <span className={`${isActive ? "translate-x-6" : "translate-x-1"} inline-block h-4 w-4 transform rounded-full bg-white transition`} />
                    </Switch>
                </div>
            </form>
        </div>
    );
};

export default ReservationCheckoutPlanRow;