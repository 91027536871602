import React from "react";
import {useDispatch} from "useDispatch";
import {useTranslation} from "react-i18next";
import clsx from "clsx";
import ModalScrollableClose from "components/ModalScrollableClose";
import CardComponent from "pages/Front/Booking/components/CardComponent";
import {useTypedSelector} from "helpers/reducers";
import {Reservation} from "types/entities/Reservation";
import CheckoutPlanner
    from "pages/Front/Booking/Seller/BookingSellerReservationDetail/components/CheckoutPlanner/CheckoutPlanner";
import {QueueListIcon} from "@heroicons/react/24/solid";

interface BookingSellerPaymentPlanModalProps {
    reservation_id: number;
}

const BookingSellerPaymentPlanModal = (props: BookingSellerPaymentPlanModalProps) => {
    const dispatch = useDispatch();
    const {t} = useTranslation();
    const reservations = useTypedSelector(state => state.seller.reservations);
    const reservation = reservations.find(reservation => reservation.id === props.reservation_id) as Reservation; // this is always a reservation

    return (
        <CardComponent
            // className={"BookingSellerPaymentPlanModal md:w-[800px] !p-0 border border-gray-200"}
            className={
                "BookingSellerUpsellsModal w-full md:w-[900px] max-w-90vw !p-0 border border-gray-200 relative overflow-hidden h-auto max-h-full flex flex-col"
            }
        >
            <div
                className={"flex flex-row justify-between w-full px-3 py-3 font-bold border-b-2 border-gray-200 bg-gray-100/20 text-gray-900 drop-shadow-sm"}>
                <div className={"flex flex-row items-center"}>
                    <QueueListIcon className={clsx("w-5 h-5 mr-1 text-gray-600/70 drop-shadow-xl")} />
                    <h4 className={"font-medium text-gray-700"}>{t("Payment plan")}</h4>
                </div>
                <ModalScrollableClose />
            </div>
            <div className={"flex w-full flex-col px-3 pt-3 pb-4 bg-gray-50 overflow-hidden h-auto rounded-bl-lg rounded-br-lg"}>
                <div className={"flex overflow-y-auto h-full w-full"}>
                    <CheckoutPlanner reservation={reservation} />
                </div>
            </div>
        </CardComponent>
    );
};

export default BookingSellerPaymentPlanModal;