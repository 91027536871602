import React, {useMemo} from "react";
import countries from "i18n-iso-countries";

const useGetCountryCode = () => {
    const supportedLanguages = ['de', 'en', 'nl', 'it', 'fr', 'es', 'sk', 'cs'];

    const loadLanguagesPack = useMemo(() => {
        return Promise.all(
            supportedLanguages.map(lang => {
                return import(`i18n-iso-countries/langs/${lang}.json`)
                    .then(languagePack => {
                        countries.registerLocale(languagePack);
                    })
                    .catch(() => {
                        console.error(`Could not load language: ${lang}`);
                    });
            }),
        );
    }, [supportedLanguages]);

    const getCountryCodeFromCountryName = async (countryName: string): Promise<string | null> => {
        await loadLanguagesPack;
        for (const lang of supportedLanguages) {
            const code = countries.getAlpha2Code(countryName, lang);
            if (code) {
                return code;
            }
        }
        return null;
    }

    return {getCountryCodeFromCountryName};
};

export default useGetCountryCode;