import React from "react";
import clsx from "clsx";

interface TableRowProps {
    children: React.ReactNode | React.ReactNode[];
    className?: string;
    onClick?: () => void;
}

const TableRow = (props: TableRowProps) => {
    return (
        <tr className={clsx("TableRow even:bg-gray-50 border-b border-b-gray-200/50 last:border-b-0", props.className)} onClick={props?.onClick ? props.onClick : undefined}>
            {props.children}
        </tr>
    );
};

export default TableRow;