import de from 'date-fns/locale/de';
import en from 'date-fns/locale/en-GB';
import nl from 'date-fns/locale/nl';
import hu from 'date-fns/locale/hu';
import sk from 'date-fns/locale/sk';

export const declareLocaleByConfig = (locale): Locale => {
    switch (locale) {
        case "en-GB":
            return en;
        case "de-CH":
            return de;
        case "nl-NL":
            return nl;
        case "hu-HU":
            return hu;
        case "sk-SK":
            return sk;
        default:
            return en;
    }
}
