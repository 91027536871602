import React from "react";
import clsx from "clsx";

interface TableProps {
    children: React.ReactNode | React.ReactNode[];
    classNameWrapper?: string;
    className?: string;
    responsive?: boolean;
}

const Table = (props: TableProps) => {
    return (
        <div className={clsx("TableWrapper w-full", props.responsive && "overflow-x-auto", props.classNameWrapper)}>
            <table className={clsx("Table w-full max-w-full", props.className)}>
                {props.children}
            </table>
        </div>
    );
};

export default Table;