import React, {useEffect} from "react";
import {Package} from "types/entities/Package";
import {BookingFilter} from "types/app";
import ButtonSubmit from "pages/Front/Booking/components/ButtonSubmit";
import {useTranslation} from "react-i18next";
import {useDispatch} from "useDispatch";
import BookingAction from "store/booking/BookingAction";
import {useTypedSelector} from "helpers/reducers";
import {toast} from "react-toastify";
import {getSelectedUpsellPackages, useClearUpsells} from "utils/packageUtils";
import useNavigation, {RouteName} from "utils/hooks/useNavigation";

interface Props {
    Package: Package;
    bookingFilter: BookingFilter;
}

const BookingUpsellFooter = (props: Props) => {
    const dispatch = useDispatch();
    const {t} = useTranslation();
    const {historyPush} = useNavigation();
    const bookingProcess = useTypedSelector(state => state.bookingState.bookingProcess);
    const packageCart = useTypedSelector(state => state.bookingState.bookingProcess.packageCart);
    const selectedUpsells = useTypedSelector(state => state.bookingState.bookingProcess.upsell);
    const selectedUpsellPackages = getSelectedUpsellPackages(selectedUpsells);
    const clearPackagesHook = useClearUpsells(bookingProcess);

    const submitPackageUpsells = (): void => {
        if (!packageCart) {
            toast.error("No packageCart found");
            return;
        }
        dispatch(
            BookingAction.fetchBookPackageWithUpsell(
                {
                    timeslot_id: null,
                    package_id: props.Package.package_id,
                    adults: props.bookingFilter.adults,
                    children: props.bookingFilter.children,
                    upsell: selectedUpsellPackages,
                },
                packageCart
            )
        ).then(r => {});

        if (props.Package) {
            // history.push("/booking/book/" + props.Package.package_id + "?" + appendQueryParams(props.bookingFilter));
            return historyPush(RouteName.Timeslots, "bookingUpsellFooter - submitPackageUpsells", props.Package);
        }
    };

    const skipPackageUpsellHandler = (): void => {
        if (props.Package) {
            dispatch(BookingAction.clearUpsells());
            return historyPush(RouteName.Timeslots, "bookingUpsellFooter - skipPackageUpsellHandler", props.Package);

            // history.push("/booking/book/" + props.Package.package_id + "?" + appendQueryParams(props.bookingFilter));
        }
    };

    useEffect(() => {
        clearPackagesHook.clearInvalidUpsells();
    }, [selectedUpsells]);

    return (
        <div className={"flex flex-row justify-center"}>
            {/*<button*/}
            {/*    className={"hidden px-4 py-2 bg-gray-300 text-gray-800 inline-flex rounded-md justify-center items-center"}*/}
            {/*    id={"ButtonSkipUpsells"}*/}
            {/*    type={"button"}*/}
            {/*    onClick={skipPackageUpsellHandler}*/}
            {/*>*/}
            {/*    <span className={"pl-2"}>{t("Skip")}</span>*/}
            {/*    <ChevronRightIcon className={"ml-2 w-4 h-4"} />*/}
            {/*</button>*/}
            <div className={"flex flex-col grow"}>
                <ButtonSubmit
                    className={"rounded-md"}
                    icon={"ticket"}
                    id="ButtonSubmitUpsells"
                    label={selectedUpsells.length === 0 ? t("Continue without upsells") : t("Continue to time selection")}
                    onClick={submitPackageUpsells}
                />
            </div>
        </div>
    );
};

export default BookingUpsellFooter;
