import React from "react";
import clsx from "clsx";

interface BookingCardProps {
    className?: string;
    children: React.ReactNode | React.ReactNode[];
}

const BookingCard = (props: BookingCardProps) => {
    return (
        <div className={clsx("BookingCard border rounded-md px-3 py-2.5 xl:px-4 xl:py-3 bg-white shadow", props.className)}>
            {props.children}
        </div>
    );
};

export default BookingCard;