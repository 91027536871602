import React from "react";
import CookieRow from "components/Cookies/components/CookieRow";
import {useForm} from "react-hook-form";
import {useDispatch} from "useDispatch";
import {useTypedSelector} from "helpers/reducers";
import {Cookies_status} from "types/enums/Cookies_status";
import BookingAction from "store/booking/BookingAction";
import {closeModal} from "helpers/actions";
import {useTranslation} from "react-i18next";

const CookiesModal = () => {
    const dispatch = useDispatch();
    const {t} = useTranslation();
    const cookies = useTypedSelector(state => state.bookingState.cookies);
    const {
        register,
        handleSubmit,
    } = useForm({
        defaultValues: {
            tech_cookies: true,
            analytics_cookies: false,
            advert_cookies: false,
        },
    });

    const submitAllHandler = data => {
        data.tech_cookies = true;
        data.analytics_cookies = true;
        data.advert_cookies = true;
        dispatch(
            BookingAction.setCookies({
                ...cookies,
                ...data,
                status: Cookies_status.AGREED,
            })
        );
        dispatch(closeModal());
    };

    const submitHandler = data => {
        data.tech_cookies = true;
        dispatch(closeModal());
        dispatch(
            BookingAction.setCookies({
                ...cookies,
                ...data,
                status: Cookies_status.AGREED,
            })
        );
        dispatch(closeModal());
    };

    return (
        <div className={"BookingFilterMinimal min-w-xs w-full flex flex-col p-4 bg-white rounded-md shadow-md items-stretch justify-center space-y-4"}>
            <div className="CookiesModal">
                <div className="d-flex flex-wrap justify-content-center">
                    <h1>Cookies - {t("Settings")}</h1>
                </div>
                <form className={"mt-4"}>
                    <CookieRow disabled={true} inputName={"tech_cookies"} register={register} title={t("Functional cookies")}>
                        {t("They are essential for the proper functioning of the site and all the features it offers. You cannot disagree with this choice")}.
                    </CookieRow>
                    <CookieRow disabled={false} inputName={"analytics_cookies"} register={register} title={t("Analytical cookies")}>
                        {t("They allow us to measure the performance of our site and our advertising campaigns")}.
                    </CookieRow>
                    <CookieRow disabled={false} inputName={"advert_cookies"} register={register} title={t("Advertising cookies")}>
                        {t("We or our partners use to display relevant content or ads to you, both on our sites and on third party sites")}.
                    </CookieRow>
                    <div className="flex justify-center space-x-4 mt-3">
                        <button className={"bg-primary-800 px-3 py-1 rounded-full text-white"} onClick={handleSubmit(submitHandler)}>
                            {t("Save preference")}
                        </button>
                        <button className={"bg-cta-800 px-6 py-3 rounded-full text-contrast-800"} type={"submit"} onClick={handleSubmit(submitAllHandler)}>
                            {t("Allow all")}
                        </button>
                    </div>
                </form>
            </div>
        </div>
    );
};

export default CookiesModal;
