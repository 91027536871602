import React from "react";
import {useTranslation} from "react-i18next";
import {UseFormSetValue} from "react-hook-form/dist/types";
import {BookingFilter} from "types/app";
import {Package} from "types/entities/Package";
import clsx from "clsx";
import {MinusIcon, PlusIcon} from "@heroicons/react/24/outline";

interface HoursCountFilterProps {
    showTitle: boolean;
    register: any;
    setValue: UseFormSetValue<BookingFilter>;
    watch: any;
    onChange: (type: string, value: string) => void;
    package?: Package | null;
}

const HoursCountFilter = (props: HoursCountFilterProps) => {
    const {t} = useTranslation();

    const disabled = false;
    const hoursCount = props.watch("hours_count");
    const minimalValue = 1;
    const maximalValue = 8;

    const setHoursCountHandler = (value: number) => {
        const newCount = +hoursCount + value;
        if (newCount < minimalValue) {
            setFilterValue(minimalValue);
            return;
        }
        if (newCount > maximalValue) {
            setFilterValue(maximalValue);
            return;
        }
        setFilterValue(newCount);
    };

    const setFilterValue = value => {
        props.setValue("hours_count", value);
        props.onChange("hours_count", value + "");
    };

    const valueButtonClassName =
        (disabled ? "bg-gray-700 cursor-not-allowed" : "bg-cta-800 text-contrast-800 cursor-pointer") +
        "  hover:no-underline text-3xl flex-grow text-center flex flex-row justify-center items-center min-w-[40px] sm:min-w-[44px]";

    return (
        <div className={"HoursCountFilter flex flex-row justify-between items-center space-x-2 xl:space-x-4"}>
            {props.showTitle && (
                <div className={"HoursCountFilterTitle mb-2"}>
                    <h3>
                        <b>{t("Number of hours")}</b>
                    </h3>
                    {/*<h4 className={"text-sm"}>{t("How many people in these activities?")}</h4>*/}
                </div>
            )}
            <div
                className={clsx(
                    disabled ? "border-gray-800" : "bg-cta-800",
                    "flex flex-row justify-between items-stretch h-10 w-1/2 md:w-1/2 max-w-[144px] border rounded-md overflow-hidden"
                )}
            >
                <button className={valueButtonClassName} id={"DecrementHoursCount"} type={"button"} onClick={() => setHoursCountHandler(-0.5)}>
                    <MinusIcon className={"pointer-events-none w-5 h-5"} />
                </button>
                <div className={"bg-white text-xl text-gray-800 bg-white leading-4 text-center flex flex-row justify-center items-center"}>
                    <input
                        className="shrink w-full px-1 text-center h-full text-black hover:outline-cta-800 hover:appearance-none	"
                        inputMode={"numeric"}
                        max={maximalValue}
                        min={minimalValue}
                        style={{
                            appearance: "textfield",
                            WebkitAppearance: "none",
                            MozAppearance: "textfield",
                        }}
                        type={"text"}
                        value={hoursCount}
                        onChange={e => setHoursCountHandler(+e.target.value)}
                    />
                </div>
                <input {...props.register("hours_count")} name={"hours_count"} type="hidden" />
                <button className={valueButtonClassName} id={"IncrementHoursCount"} type={"button"} onClick={() => setHoursCountHandler(+0.5)}>
                    <PlusIcon className={"pointer-events-none w-5 h-5"} />
                </button>
            </div>
        </div>
    );
};

export default HoursCountFilter;
