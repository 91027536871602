import React from "react";
import {useTranslation} from "react-i18next";
import {Link} from "react-router-dom";
import {ChevronLeftIcon} from "@heroicons/react/24/solid";
import BookingLoginInput from "pages/Front/Booking/components/BookingLoginInput";
import ButtonSubmit from "pages/Front/Booking/components/ButtonSubmit";
import BookingLoginErrors from "pages/Front/Booking/BookingLogin/components/BookingLoginErrors";

interface SellerForgottenPasswordForgottenPasswordRecoveryFormProps {
    onSubmit: (data) => void;
    register: any;
    errors: any;
    isSubmitting?: boolean;
}

const SellerForgottenPasswordForgottenPasswordRecoveryForm = (props: SellerForgottenPasswordForgottenPasswordRecoveryFormProps) => {
    const {t} = useTranslation();

    const onSubmitHandler = () => {
        props.onSubmit;
    }

    return (
        <form
            className="SellerForgottenPasswordForgottenPasswordRecoveryForm w-full"
            id="wf-form-ForgottenPassword-Form"
            method="POST"
            name="wf-form-ForgottenPassword-Form"
            onSubmit={props.onSubmit}
        >
            <div className={"my-2 text-center space-y-2"}>
                <label className="text-base font-bold text-gray-900">{t("Password recovery")}</label>
                <div className={"leading-4 px-3"}>
                    <p className={"text-gray-600"}>{t("To reset your password, enter the full email address that you use to log in.")}</p>
                </div>
            </div>

            <div>
                <BookingLoginInput
                    className={"w-full pl-10"}
                    errors={props.errors}
                    label={t("Email")}
                    name={"email"}
                    placeholder={"mail@example.com"}
                    register={props.register}
                    required={true}
                    showIcon={true}
                    type={"email"}
                />
            </div>

            {props?.errors && <BookingLoginErrors errors={props.errors} />}

            <div className={"sm:col-span-6 mt-4"}>
                <div className={"flex justify-center"}>
                    <ButtonSubmit
                        buttonType={"cta"}
                        className={"rounded-md"}
                        disabled={props.isSubmitting}
                        icon={"note"}
                        isLoading={props.isSubmitting}
                        label={t("Reset password")}
                        onClick={onSubmitHandler}
                    />
                </div>
            </div>

            <div className="relative flex flex-col items-center mt-4">
                <Link
                    className={"flex space-x-1.5 items-center hover:text-black"}
                    to={"/booking/seller/login-with-password"}>
                    <ChevronLeftIcon className={"w-4 h-4 flex-none"} />
                    <span>{t("Back to main")}</span>
                </Link>
            </div>
        </form>
    );
};

export default SellerForgottenPasswordForgottenPasswordRecoveryForm;