import React from "react";
import {Driver} from "types/entities/Driver";
import clsx from "clsx";
import {PersonType} from "types/enums/PersonType";
import {UsersIcon} from "@heroicons/react/24/solid";

interface CustomerProfilePictureProps {
    driver?: Driver | null;
}

const CustomerProfilePicture = (props: CustomerProfilePictureProps) => {
    return (
        <div
            className={clsx("CustomerProfilePicture rounded-lg p-[6px] xl:p-2 mr-3 w-full h-full object-fit", props.driver?.person_type === PersonType.Adult ? "bg-blue-400" : "bg-green-400")}>
            {!props.driver?.profile_picture_url ? <img alt="" src={props.driver?.profile_picture_url} /> :
                <UsersIcon className={"w-4 h-4 flex-none"} />}
        </div>
    );
};

export default CustomerProfilePicture;