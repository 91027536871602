import React from "react";
import clsx from "clsx";

interface StrongTextProps {
    text: string;
    className?: string;
}

export const StrongText = (props: StrongTextProps) => {
    return <div className={clsx(props.className, "font-medium  my-0")}>{props.text}</div>;
};
