import clsx from "clsx";
import React from "react";

interface BookingLoginReservationsTableProps {
    className?: string;
    children: React.ReactNode | React.ReactNode[];
}

const BookingLoginReservationsTable = (props: BookingLoginReservationsTableProps) => {
    return (
        <div className={clsx("BookingLoginReservationsTable w-full", props.className)}>
            {props.children}
        </div>
    );
};

export default BookingLoginReservationsTable;