import React from "react";
import CardComponent from "./CardComponent";
import CardLabel from "./BookingCustomer/CardLabel";
import {useTranslation} from "react-i18next";
import clsx from "clsx";
import {DEFAULT_HALL} from "helpers/config";
import {HallCode} from "types/enums/HallCode";
import {Link} from "react-router-dom";

interface BookingLoginWidgetProps {
    className?: string;
}

const BookingLoginWidget = (props: BookingLoginWidgetProps) => {
    const {t} = useTranslation();
    return (
        <CardComponent className={clsx("BookingLoginWidget mb-8", props.className)}>
            <div className={"relative flex flex-col w-full justify-between"}>
                <CardLabel title={t("Send security code")} />
                <div className={"flex flex-row mt-2"}>
                    <Link
                        className={clsx(
                            DEFAULT_HALL === HallCode.Zwolle ? "bg-cta-800 text-white border-cta-800 " : "bg-primary-800 text-white border-primary-800 ",
                            "ButtonBookingVoucher w-1/2 rounded-md  hover:no-underline h-10 -mr-px grow inline-flex items-center text-center justify-center px-1 py-2 text-base sm:leading-3",
                            "bg-primary-800 text-white disabled:bg-gray-500 disabled:cursor-not-allowed"
                        )}
                        to={"/booking/login"}
                    >
                        {t("Go to login")}
                    </Link>
                </div>
            </div>
        </CardComponent>
);
};

export default BookingLoginWidget;