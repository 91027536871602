import React from "react";
import {useTranslation} from "react-i18next";
import clsx from "clsx";

// preddefinovany styl buttonu podla akcie, kazdy bude mat svoju roznu farbu
export enum UiButtonStyle {
    Primary = "primary",
    SECONDARY = "secondary",
    SAVE = "save",
    EDIT = "edit",
    DELETE = "delete",
    CLOSE = "close",
    Add = "add",
    INFO = "info",
    Print = "print",
    LIGHT = "light",
    Dark = "dark",
    Gray = "gray",
    Green = "green",
    Red = "red",
    Blue = "blue",
    Cyan = "cyan",
    Disabled = "disabled",
    Pagination = "pagination",
}

export enum UiButtonType {
    Submit = "submit",
    Button = "button",
}

export enum UiButtonSize {
    Small = "small",
    Normal = "normal",
    Medium = "medium",
    Large = "large",
    Pagination = "pagination",
}

interface UiButtonProps {
    className?: string;
    icon?: React.ReactNode | React.ReactNode[];
    onClick?: () => void;
    // onClick?: React.MouseEventHandler<HTMLButtonElement>;
    title?: string;
    type?: UiButtonType;
    style?: UiButtonStyle; // TODO: predefined button style
    size?: UiButtonSize;
    disabled?: boolean;
    children?: React.ReactNode | React.ReactNode[];
    data?: any;
}

const UiButton = (props: UiButtonProps) => {
    const {t} = useTranslation();
    // focus:outline-none focus:ring-2 focus:ring-offset-2 focus:ring-dark-700
    // const classElement = "inline-flex items-center px-2 py-1 xl:px-4 xl:py-1 text-base xl:text-lg shadow-sm shadow-black/10 text-white font-medium bg-dark-700 hover:bg-dark-900 transition duration-150 border border-transparent rounded-full";
    const classElement = "inline-flex items-center shadow-sm shadow-black/10 transition duration-150 border rounded justify-center";
    // TODO: doriesit size cez switch???
    // const classSize = props.size === UiButtonSize.Small ? "!px-3 !py-1.5 !text-base !font-normal !leading-none" : "";
    return (
        <button
            className={clsx(
                "UiButton",
                classElement,
                props.className && props.className,
                // classSize,
                props.size === UiButtonSize.Small && "px-3 py-1.5 text-base font-normal leading-none",
                // props.size === UiButtonSize.Medium && "px-4 py-3 text-base font-medium leading-none",
                props.size === UiButtonSize.Normal && "px-3 py-2 text-base font-bold leading-none",
                props.size === UiButtonSize.Medium && "px-4 py-3 text-base font-medium leading-none",
                props.size === UiButtonSize.Pagination && "px-1.5 py-1 text-base font-normal leading-none",
                props.disabled && "pointer-events-none cursor-not-allowed !bg-gray-300 !border-gray-300 !text-black/30",
                // !props.size && "px-5 py-2 text-lg font-medium text-white bg-dark-700 hover:bg-dark-900 border-transparent",
                !props.size && "px-5 py-2 text-lg font-medium",
                props.style === UiButtonStyle.Primary && "bg-blue-400 hover:bg-blue-500 text-white",
                props.style === UiButtonStyle.Blue && "bg-blue-400 hover:bg-blue-500 text-white border-transparent",
                props.style === UiButtonStyle.Dark && "text-white bg-dark-700 hover:bg-dark-900 border-transparent",
                props.style === UiButtonStyle.Gray && "text-white bg-gray-600 hover:bg-gray-700 border-transparent",
                // props.style === UiButtonStyle.Disabled && "text-white bg-gray-400 border-transparent",
                props.style === UiButtonStyle.Cyan && "text-white bg-cyan-500 hover:bg-cyan-600 border-transparent",
                props.style === UiButtonStyle.Add && "text-white bg-cta-400 hover:bg-cta-800 border-cta-400 hover:border-cta-800",
                props.style === UiButtonStyle.Green && "text-white bg-green-500 hover:bg-green-600 border-transparent",
                props.style === UiButtonStyle.Red && "text-white bg-red-500 hover:bg-red-600 border-transparent",
                props.style === UiButtonStyle.Print && "text-white bg-blueGray-600 hover:bg-blueGray-700 border-transparent",
                props.style === UiButtonStyle.Pagination && " bg-white hover:text-white hover:bg-blue-400 hover:border-blue-400",
                !props.style && "text-white bg-blue-400 hover:bg-blue-500 border-transparent",
            )}
            disabled={props.disabled}
            type={props.type ? props.type : UiButtonType.Button}
            onClick={props.onClick && props.onClick}
        >
            {props.icon && props.icon}
            {props.title && <span className={props.icon ? "ml-2" : ""}>{t(props.title)}</span>}
            {props.children && props.children}
        </button>
    );
};

export default UiButton;