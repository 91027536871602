import React from "react";
import clsx from "clsx";

interface TableColumnProps {
    children: React.ReactNode | React.ReactNode[];
    className?: string;
}

const TableColumn = (props: TableColumnProps) => {
    return (
        <td className={clsx("TableColumn py-2 px-1.5 first:!pl-3 last:!pr-3", props.className)}>
            {props.children}
        </td>
    );
};

export default TableColumn;