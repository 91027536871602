import React from "react";
import {Reservation} from "types/entities/Reservation";
import BookingCard from "pages/Front/Booking/components/BookingCard";
import {useTranslation} from "react-i18next";
import {useDispatch} from "useDispatch";
import clsx from "clsx";
import Loader from "components/Loader";
import {ReservationNote} from "types/entities/ReservationNote";
import BookingSellerNoteItem
    from "pages/Front/Booking/Seller/BookingSellerReservationDetail/components/BookingSellerNoteItem";
import {PlusIcon} from "@heroicons/react/24/outline";
import {EyeIcon} from "@heroicons/react/24/solid";
import {closeModal, openModal} from "helpers/actions";
import {ModalSize} from "pages/Front/components/Popup";
import BookingSellerNotesAddFormModal
    from "pages/Front/Booking/Seller/BookingSellerReservationDetail/components/BookingSellerNotesAddFormModal";
import BookingLoginAction from "store/bookingLogin/BookingLoginAction";
import BookingSellerNotesAllModal
    from "pages/Front/Booking/Seller/BookingSellerReservationDetail/components/BookingSellerNotesAllModal";

interface BookingSellerNotesProps {
    className?: string;
    reservation: Reservation;
}

const BookingSellerNotes = (props: BookingSellerNotesProps) => {
    const {t} = useTranslation();
    const dispatch = useDispatch();

    const onClickShowAllHandler = () => {
        dispatch(
            openModal(<BookingSellerNotesAllModal reservation={props.reservation} />, {
                modalSize: ModalSize.Scrollable,
            })
        );
    }

    const onClickAddNoteHandler = () => {
        dispatch(
            openModal(<BookingSellerNotesAddFormModal reservation={props.reservation} />, {
                modalSize: ModalSize.Scrollable,
                closeModal: () => {
                    // toast.success("Closing now");
                    dispatch(BookingLoginAction.fetchSellerReservationDetail(props.reservation.id)).then(r => {
                        dispatch(closeModal());
                    });
                },
            })
        );

    }

    const replyNote = (reservationNote: ReservationNote) => {
        if (reservationNote.replied_note_id === null && !props.reservation?.reservationNotes) {
            return null;
        }
        return props.reservation.reservationNotes && props.reservation.reservationNotes.find(note => note.id === reservationNote.replied_note_id);
    };

    if (!props.reservation) {
        return <Loader />;
    }

    return (
        <BookingCard className={clsx("BookingSellerNotes !px-0 !py-0 self-baseline", props.className)}>
            <h2 className={clsx("BookingCardTitle font-bold text-xl text-black px-3 py-2.5 xl:px-3 xl:py-3 flex items-center justify-between border-b")}>
                <div className={"inline-flex items-center gap-x-2"}>
                    <span>{t("Notes")}</span>
                </div>
                <div className={"flex flex-row space-x-2"}>
                    <button className={"bg-blue-400 hover:bg-blue-500 px-2 py-1 text-white rounded text-base inline-flex items-center"}
                            onClick={onClickAddNoteHandler}>
                        <PlusIcon className={"w-4 h-4 flex-none mr-1.5"} />
                        <span>{t("Add note")}</span>
                    </button>
                    <button className={"bg-gray-700 hover:bg-gray-800 px-2 py-1 text-white rounded text-base inline-flex items-center"}
                            onClick={onClickShowAllHandler}>
                        <EyeIcon className={"w-4 h-4 flex-none mr-1.5"} />
                        <span>{t("Show all")}</span>
                    </button>
                </div>
            </h2>

            <div className={"px-3 py-3"}>
                {(!props.reservation?.reservationNotes || (props.reservation.reservationNotes && props.reservation.reservationNotes.length === 0)) && (
                    <div className={"text-center"}>{t("No data")}</div>
                )}
                {props.reservation?.reservationNotes && props.reservation.reservationNotes.length > 0 && (
                    <ul className={"w-full py-0"} role="list">
                        {props.reservation.reservationNotes
                            .sort((a, b) => (a.created_at < b.created_at ? 1 : -1))
                            .slice(0, 2)
                            .map((reservationNote, index) => {
                                return (
                                    <BookingSellerNoteItem
                                        key={index}
                                        replyNote={replyNote(reservationNote)}
                                        reservation={props.reservation}
                                        reservationNote={reservationNote}
                                    />
                                );
                            })}
                    </ul>
                )}
            </div>
        </BookingCard>
    );
};

export default BookingSellerNotes;