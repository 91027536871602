import React from "react";
import {Input} from "pages/Front/Booking/components/BookingInput";
import clsx from "clsx";

interface Props {
    title: string;
    inputName: string;
    register: any;
    children: React.ReactNode | React.ReactNode[];
    disabled?: boolean;
}

const CookieRow = (props: Props) => {
    return (
        <div className="CookieRow">
            <div className="flex justify-between">
                <label className={clsx(props.disabled && "pointer-events-none opacity-80", "flex flex-row space-x-4")} htmlFor={props.inputName}>
                    <Input
                        className={"h-4 w-4"}
                        disabled={props.disabled}
                        label={""}
                        labelLess={true}
                        name={props.inputName}
                        register={props.register}
                        type={"checkbox"}
                    />
                    <h2 className={"grow ml-2 font-bold"}>{props.title}</h2>
                </label>
            </div>
            <div className={"text-left w-75"}>{props.children}</div>
        </div>
    );
};

export default CookieRow;
