import React from "react";
import {useTranslation} from "react-i18next";
import BookingLoginReservationDetailButton
    from "pages/Front/Booking/BookingLogin/components/BookingLoginReservationDetail/components/BookingLoginReservationDetailButton";
import {XMarkIcon} from "@heroicons/react/24/outline";
import {CheckIcon, PencilIcon} from "@heroicons/react/24/solid";
import clsx from "clsx";
import BookingLoginReservationButtonInner
    from "pages/Front/Booking/BookingLogin/components/BookingLoginReservationDetail/components/BookingLoginReservationButtonInner";
import {BookingLoginReservationButtonSize} from "types/enums/BookingLoginReservationButtonSize";
import {BookingLoginReservationButtonStyle} from "types/enums/BookingLoginReservationButtonStyle";
import BookingLoginReservationButton
    from "pages/Front/Booking/BookingLogin/components/BookingLoginReservationDetail/components/BookingLoginReservationButton";

interface BookingLoginReservationDetailDriverActionsRedesignProps {
    isEditing: boolean;
    driver_id?: null | number;
    onEditHandler: (e: any) => void;
    onSubmitHandler: (e: any) => void;
    onCancelHandler?: (e: any) => void;
    className?: string;
    isLoading: boolean;
}

const BookingLoginReservationDetailDriverActionsRedesign = (props: BookingLoginReservationDetailDriverActionsRedesignProps) => {
    const {t} = useTranslation();
    return (
        <div
            className={clsx(
                "BookingLoginReservationDetailDriverActionsRedesign flex flex-row space-x-2",
                (props.isEditing && props?.driver_id) && "w-full justify-between",
                ((props.isEditing && props?.driver_id) || !props?.driver_id) && "w-full sm:justify-between md:justify-end",
                props.className
            )}
        >
            {(!props.isEditing && props?.driver_id) &&
                <BookingLoginReservationButton
                    onClick={(e) => props.onEditHandler(e)}
                >
                    <BookingLoginReservationButtonInner
                        // className={"sm:py-2 sm:px-4 md:py-1 md:!px-1"}
                        size={BookingLoginReservationButtonSize.Small}
                        style={BookingLoginReservationButtonStyle.Blue}
                    >
                        <PencilIcon className={"flex-none w-[16px] h-[16px]"} />
                        {/*<span className={"hidden sm:flex sm:ml-2 md:ml-1 text-base md:text-sm"}>{t("Edit")}</span>*/}
                        <span className={"ml-1.5"}>{t("Edit")}</span>
                    </BookingLoginReservationButtonInner>
                </BookingLoginReservationButton>
            }

            {(props.isEditing && props?.driver_id) &&
                <BookingLoginReservationButton
                    onClick={(e) => props?.onCancelHandler ? props.onCancelHandler(e) : (e)}
                >
                    <BookingLoginReservationButtonInner
                        // className={"sm:py-2 sm:px-4 md:py-1 md:!px-1"}
                        size={BookingLoginReservationButtonSize.Small}
                        style={BookingLoginReservationButtonStyle.Gray}
                    >
                        <XMarkIcon className={"flex-none w-[18px] h-[18px]"} />
                        {/*<span className={"sm:ml-2 md:ml-1 text-base md:text-sm"}>{t("Cancel")}</span>*/}
                        <span className={"ml-1.5"}>{t("Cancel")}</span>
                    </BookingLoginReservationButtonInner>
                </BookingLoginReservationButton>
            }

            {((props.isEditing && props?.driver_id) || !props?.driver_id) &&
                <BookingLoginReservationButton
                    disabled={props.isLoading}
                    onClick={props.onSubmitHandler}
                >
                    <BookingLoginReservationButtonInner
                        // className={"sm:py-2 sm:px-4 md:py-1 md:!px-1"}
                        loading={props.isLoading}
                        size={BookingLoginReservationButtonSize.Small}
                        style={BookingLoginReservationButtonStyle.Green}
                    >
                        <CheckIcon className={"flex-none w-[18px] h-[18px]"} />
                        {/*<span className={"sm:ml-2 md:ml-1 text-base md:text-sm"}>{t("Save")}</span>*/}
                        <span className={"ml-1.5"}>{t("Save")}</span>
                    </BookingLoginReservationButtonInner>
                </BookingLoginReservationButton>
            }
        </div>
    );
};

export default BookingLoginReservationDetailDriverActionsRedesign;