import {ReservationDriverStatus} from "types/enums/ReservationDriverStatus";
import {useTranslation} from "react-i18next";
import {isValidPhoneNumberFormat, listOfAllMissingRequiredData} from "utils/utility";
import {BookingReservationIdentifier} from "types/enums/BookingReservationIdentifier";
import {ReservationDriver} from "types/entities/ReservationDriver";

const useDriverStatus = () => {
    const {t} = useTranslation();

    const getStatus = (reservationDriver: ReservationDriver, hasAccessAssigned: boolean) => {
        const driverAdditionalInfo = reservationDriver?.driver;
        const missingFields = driverAdditionalInfo ? listOfAllMissingRequiredData(driverAdditionalInfo) : undefined;
        return missingFields === undefined
            ? ReservationDriverStatus.None
            : (driverAdditionalInfo && missingFields.length === 0 && hasAccessAssigned)
                ? isValidPhoneNumberFormat(driverAdditionalInfo?.phone)
                    ? ReservationDriverStatus.Ready
                    : ReservationDriverStatus.RequiredEditPhone
                : reservationDriver?.identifier !== BookingReservationIdentifier.Manual
                    ? ReservationDriverStatus.InvitedAndWaiting
                    : hasAccessAssigned
                        ? ReservationDriverStatus.RequiresEditing
                        : ReservationDriverStatus.RequiresAccessTicket;
    }

    const getStatusAdditionalInfo = (status: ReservationDriverStatus) => {
        switch(status) {
            case ReservationDriverStatus.Ready:
                return {
                    bulletColor: "text-green-400",
                    textColor: "text-green-400",
                    label: t("Ready")
                }
            case ReservationDriverStatus.InvitedAndWaiting:
                return {
                    bulletColor: "text-orange-400",
                    textColor: "text-orange-400",
                    label: t("Invited, waiting for data")
                }
            case ReservationDriverStatus.RequiresAccessTicket:
                return {
                    bulletColor: "text-orange-400",
                    textColor: "text-orange-400",
                    label: t("Choose Entry ticket")
                }
            case ReservationDriverStatus.RequiresEditing:
                return {
                    bulletColor: "text-red-400",
                    textColor: "text-red-400",
                    label: t("Requires editing")
                }
            case ReservationDriverStatus.RequiredEditPhone:
                return {
                    bulletColor: "text-red-400",
                    textColor: "text-red-400",
                    label: t("Phone number in incorrect format")
                }
            case ReservationDriverStatus.None:
                return {
                    bulletColor: "text-gray-400",
                    textColor: "text-gray-500",
                    label: t("None")
                }
            default:
                return {
                    bulletColor: "text-gray-400",
                    textColor: "text-gray-500",
                    label: t("None")
                }
        }
    }

    return {
        getStatus,
        getStatusAdditionalInfo
    };
};

export default useDriverStatus;