import React from "react";
import {useTranslation} from "react-i18next";
import {ExclamationTriangleIcon, CheckCircleIcon} from "@heroicons/react/24/outline";
import clsx from "clsx";

interface BookingLoginReservationDetailDriverNoticeProps {
    driver_id?: number;
    className?: string;
}

const BookingLoginReservationDetailDriverNotice = (props: BookingLoginReservationDetailDriverNoticeProps) => {
    const {t} = useTranslation();
    return (
        <div className={clsx("BookingLoginReservationDetailDriverNotice", props.className)}>
            <div className={"flex flex-row items-start sm:items-center"}>
                {props?.driver_id &&
                    <>
                        <CheckCircleIcon className={"w-[20px] h-[20px] basis-auto grow-0 shrink-0 mr-1 text-green-500"} />
                        <span className={"text-green-600"}>{t("This customer is prepared in reservation")}</span>
                    </>
                }
                {!props?.driver_id &&
                    <>
                        <ExclamationTriangleIcon className={"w-[18px] h-[18px] basis-auto grow-0 shrink-0 mr-1 text-orange-500"} />
                        <span className={"text-orange-500"}>{t("You must save the visitor to assign him to the reservation")}</span>
                    </>
            }
            </div>
        </div>
    );
};

export default BookingLoginReservationDetailDriverNotice;