import {useDispatch} from "useDispatch";
import React, {useEffect} from "react";
import BookingAction from "store/booking/BookingAction";
import Loader from "components/Loader";
import CardComponent from "pages/Front/Booking/components/CardComponent";
import {useTranslation} from "react-i18next";
import {currencyNumberFormat} from "utils/utility";
import {AtSymbolIcon, PhoneIcon} from "@heroicons/react/24/outline";
import {useTypedSelector} from "helpers/reducers";
import {PaymentStatus} from "types/enums/PaymentStatus";
import {RouteComponentProps, withRouter} from "react-router-dom";
import TagManager from "react-gtm-module";
import {ThunkAction} from "redux-thunk";
import useNavigation, {RouteName} from "utils/hooks/useNavigation";

interface Params {
    cart_id: string;
    cart_token: string;
}

interface BookingPaidProps extends RouteComponentProps<Params> {}

const BookingInquired = (props: BookingPaidProps) => {
    const dispatch = useDispatch();
    const result = useTypedSelector(state => state.reservationState.result);
    const {t} = useTranslation();
    const hall = useTypedSelector(state => state.hallState?.hall) || null;
    const packageCart = useTypedSelector(state => state.bookingState.bookingProcess).packageCart;
    const transactionTotal = packageCart ? packageCart.price / 100 : 0;
    const {historyPush} = useNavigation();

    const packageCartItems = packageCart ? packageCart.packagesCartItems : [];
    const cartTagManagerArgs = {
        dataLayer: {
            event: "BookingInquired",
            valueFormatted: currencyNumberFormat(packageCart?.price),
            transactionTotal: transactionTotal,
            userId: result?.company_id,
            paymentId: result?.id,
            paymentStatus: result?.status,
            transactionId: result?.id,
            ecommerce: {
                products: packageCartItems,
            },
        },
    };

    // const payment = useTypedSelector(state => state.bookingState.bookingProcess.payment);

    useEffect(() => {
        dispatch(BookingAction.resetReservationNote());
    }, []);
    useEffect(() => {
        if (result) {
            TagManager.dataLayer(cartTagManagerArgs);
        }
    }, [result]);

    const finishHandler = () => {
        dispatch<ThunkAction<any, any, any, any>>(BookingAction.initCart()).then(r => {
            dispatch(BookingAction.clearBookingProcess());
        });
        // props.history.push("/booking");
        return historyPush(RouteName.Main, "BookingInquired - finishHandler");
    };

    if (!result || !hall) {
        return <Loader />;
    }

    return (
        <div className={"BookingInquired w-full flex flex-col justify-start items-center"}>
            <div className={"BookingPaidInner py-3 px-2 sm:py-12 px:12 flex flex-col space-y-2 sm:space-y-8  max-w-2xl"}>
                <div className={"PaymentStatus flex flex-col space-y-2 sm:space-y-8 lg:flex-wrap justify-center items-center px-4 sm:px-12"}>
                    <h3 className={"text-lg sm:text-3xl font-black text-primary-800"}>{t("Thank you for your inquiry!")}</h3>
                    <CardComponent>
                        <div className={"text-lg"}>
                            <span>{t("Your inquiry has been successfully completed")}.</span>&nbsp;
                            <span>{t("We will contact you per E-mail, as soon as we process your inquiry")}.</span>
                        </div>
                    </CardComponent>
                </div>

                {result.status === PaymentStatus.Finished && (
                    <>
                        <div className={"PaymentCustomer flex-grow flex flex-row lg:flex-wrap justify-center items-center px-4 sm:px-12"}>
                            <CardComponent className={"w-full text-lg"}>
                                {hall.contact && (
                                    <div className={"flex flex-row justify-center items-center"}>
                                        <PhoneIcon className={"w-4 h-4 mr-2"} />{" "}
                                        <a href={`tel:${hall.contact}`}>
                                            <b>{hall.contact}</b>
                                        </a>
                                    </div>
                                )}
                                {hall.email && (
                                    <div className={"flex flex-row justify-center items-center"}>
                                        <AtSymbolIcon className={"w-4 h-4 mr-2"} />{" "}
                                        <a href={`mailto:${hall.email}`}>
                                            <b>{hall.email}</b>
                                        </a>
                                    </div>
                                )}
                            </CardComponent>
                        </div>
                    </>
                )}
            </div>

            <div className="flex w-full flex-col max-w-md">
                <button
                    className={
                        "hover:opacity-80 bg-cta-800 text-contrast-800 cursor-pointer hover:no-underline text-base sm:text-xl flex-grow text-center px-4 py-2 sm:px-5 sm:py-3 flex flex-row justify-center rounded-lg items-center"
                    }
                    onClick={finishHandler}
                >
                    {t("Close")}
                </button>
            </div>
        </div>
    );
};

export default withRouter(BookingInquired);
