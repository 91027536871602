import Dates from "utils/Dates";
import React from "react";
import {ChevronDoubleLeftIcon, ChevronDoubleRightIcon} from "@heroicons/react/20/solid";
import clsx from "clsx";

interface AvailableDateProps {
    currentDateIndex: number;
    onClick: (date: string) => void;
    availableDates: Date[] | [];
    direction: "prev" | "next";
    classNameButton?: string;
    classNameWrapper?: string;
    classNameIcon?: string;
}
const AvailableDateButton: React.FC<AvailableDateProps> = ({currentDateIndex, onClick, availableDates, direction, classNameButton, classNameWrapper, classNameIcon}) => {
    const availableDate = direction === "next" ? availableDates[currentDateIndex + 1] : availableDates[currentDateIndex - 1];

    if (!availableDate) {
        return <div className={"w-[104px] h-10"}></div>;
    }

    const isNext = direction === "next";
    const className = clsx(
        "relative py-2 text-gray-800 text-base flex flex-row items-center hover:underline hover:text-black",
        isNext ? "pl-2 pr-6 justify-end" : "pr-2 pl-6 justify-start",
        classNameButton
    );

    const selectDateHandler = (date: Date) => {
        onClick(Dates.ymd(date));
    };

    return (
        <div className={clsx("AvailableDateButton flex flex-row", isNext ? "justify-end" : "justify-start", classNameWrapper)}>
            <button className={className} disabled={!availableDate} onClick={() => selectDateHandler(availableDate)}>
                {isNext && <ChevronDoubleRightIcon className={clsx("w-5 h-5 absolute right-0", classNameIcon)} />}
                {!isNext && <ChevronDoubleLeftIcon className={clsx("w-5 h-5 absolute left-0", classNameIcon)} />}
                <span>{Dates.intlDateFormat(availableDate.getTime(), {dateStyle: "short"})}</span>
            </button>
        </div>
    );
};

export default AvailableDateButton;
