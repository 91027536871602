import React from "react";
import clsx from "clsx";
import {FieldErrors} from "react-hook-form/dist/types/errors";
import {useTranslation} from "react-i18next";

interface CompanyFormLabelProps {
    errors: FieldErrors;
    name: string;
    required?: boolean;
    label: string;
    className?: string;
}

const CompanyFormLabel = (props: CompanyFormLabelProps) => {
    const {t} = useTranslation();
    return (
        <label
            className={clsx(
                "font-medium mb-1 flex text-sm",
                props.className,
                props.errors?.[props.name] && "text-red-500"
            )}
            htmlFor={"input-" + props.name}
        >
            <span>{t(props.label)}</span>
            {props.required &&
                <em className={"text-red-500"}>*</em>
            }
        </label>
    );
};

export default CompanyFormLabel;