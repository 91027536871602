import React, {useState} from "react";
import Lottie from "lottie-react";
import FailedAnimation from "components/Animations/FailedAnimation.json";
import {StrongText} from "pages/Front/components/TextStyles/StrongText";
import {MutedText} from "pages/Front/components/TextStyles/MutedText";
import clsx from "clsx";
import {Link} from "react-router-dom";
import HomeIcon from "@heroicons/react/24/outline/HomeIcon";
import T from "components/T";
import {useTranslation} from "react-i18next";

interface SellerForgottenPasswordRecoveryErrorProps {
}

const SellerForgottenPasswordRecoveryError = (props: SellerForgottenPasswordRecoveryErrorProps) => {
    const [messageComplete, setMessageComplete] = useState<boolean>(false);
    const {t} = useTranslation();

    return (
        <div
            className={"SellerForgottenPasswordRecoveryError relative max-w-md z-3 flex flex-col space-y-4 gap-2 items-center justify-between"}>
            <div className={"flex flex-col justify-center items-center space-y-4"}>
                <div className={"w-20 h-20 rounded-full shadow-lg p-4 bg-red-100"}>
                    <Lottie
                        animationData={FailedAnimation}
                        loop={false}
                        onComplete={() => {
                            setMessageComplete(true);
                        }}
                    />
                </div>
                <StrongText text={t("Password change error")} />
                <MutedText className={"text-center"}
                    text={t("Continue to recovery from the main page. If problem remains, please contact support.")} />
            </div>
            <div className={"flex flex-col justify-center items-center"}>
                <div
                    className={clsx(messageComplete ? "opacity-100" : "opacity-0", "duration-500 ease-in-out transform-gpu")}>
                    <div className={"flex items-center shrink justify-self-start"}>
                        <Link
                            className={"flex items-center gap-2 py-2 px-4 border-2 border-green-500 hover:border-gray-300 text-green-500 font-bold rounded-lg"}
                            to={"/booking/seller/login-with-password"}
                        >
                            <HomeIcon className={"w-4 h-4 flex-none"} />
                            <T>Back to main</T>
                        </Link>
                    </div>
                </div>
            </div>
        </div>
    );
};

export default SellerForgottenPasswordRecoveryError;