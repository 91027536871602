import React from "react";
import {useTranslation} from "react-i18next";
import PackageTag from "pages/Front/Booking/components/PackageTag";
import clsx from "clsx";
import {truncateTextToLength} from "utils/utility";

interface Props {
    label: string;
    value: string;
    duration: number | string;
}

const ScheduleItem = (props: Props) => {
    const {t} = useTranslation();
    return (
        <li className={"ScheduleItem pt-4"}>
            <div className={"flex flex-row w-full space-x-4"}>
                <PackageTag
                    key={0}
                    className={clsx(
                        (+props.duration <= 5 || typeof props.duration === "string") && "bg-gray-200",
                      (+props.duration > 5 && +props.duration < 15) && "bg-primary-100",
                        +props.duration >= 15 && "bg-secondary-100",
                        "min-w-[80px] flex flex-row items-center justify-center",
                    )}
                    label={"" + props.duration + " " + t("minutes")}
                />
                <b className={"flex-grow"}>{t(props.label)}</b>
            </div>
            {props.value.length > 2 && (
                <p>{truncateTextToLength(props.value, 120)}</p>)}
        </li>
    );
};

export default ScheduleItem;
