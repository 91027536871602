import React from "react";
import {useTranslation} from "react-i18next";
import {useTypedSelector} from "helpers/reducers";
import {translate} from "utils/utility";

interface ActivityFilterProps {
    watch: any
    register: any;
    showTitle?: boolean;
    onChange: (e) => void;
}

const ActivityFilter = (props: ActivityFilterProps) => {
    const {t} = useTranslation();
    const activities = useTypedSelector(state => state.bookingState.activities);
    return (
        <div className={"ActivityFilter flex flex-col space-y-4 rounded-sm overflow-hidden"}>
            {props.showTitle && (
                <div className={"ActivityFilterTitle"}>
                    <h3>
                        <b>{t("Activities")}</b>
                    </h3>
                    {/*<h4 className={"text-sm"}>{t("What activities do you like the most?")}</h4>*/}
                </div>
            )}
            <fieldset>
                <div className="border-t border-b border-gray-200 divide-y divide-gray-200">
                    {activities.map(activity => (
                        <div key={activity.id} className="relative flex items-start py-4">
                            <div className="mr-3 flex items-center h-5 cursor-pointer">
                                <input
                                    {...props.register('activity_ids[]')}
                                    aria-describedby={`activity-${activity.id}`}
                                    className="focus:ring-cta-800 checked:bg-cta-800 h-4 w-4 text-cta-800 border-cta-100 rounded  cursor-pointer"
                                    id={`activity-${activity.id}`}
                                    name={`activity_ids[]`}
                                    type="checkbox"
                                    value={activity.id}
                                    onChange={props.onChange}
                                />
                            </div>
                            <div className="min-w-0 flex flex-grow text-sm">
                                <label className="font-medium text-gray-900 w-full flex-grow select-none cursor-pointer" htmlFor={`activity-${activity.id}`}>
                                    {translate(activity.name)}
                                </label>
                                <span className="text-gray-500" id={`activity-${activity.id}`}>
                                    <span className="sr-only">{translate(activity.name)}</span>
                                </span>
                            </div>
                        </div>
                    ))}
                </div>
            </fieldset>
        </div>
    );
};

export default ActivityFilter;
