import React, {useState} from "react";
import {useTranslation} from "react-i18next";
import {useDispatch} from "useDispatch";
import {useForm} from "react-hook-form";
import {toast} from "react-toastify";
import {Link} from "react-router-dom";
import {ChevronLeftIcon} from "@heroicons/react/24/solid";
import SellerAction from "store/seller/SellerAction";
import SellerForgottenPasswordRecoveryError
    from "pages/Front/Booking/Seller/SellerForgottenPassword/SellerForgottenPasswordRecoveryError";
import ButtonSubmit from "pages/Front/Booking/components/ButtonSubmit";
import BookingLoginInput from "pages/Front/Booking/components/BookingLoginInput";
import {yupResolver} from "@hookform/resolvers/yup";
import * as yup from "yup";
import BookingLoginErrors from "pages/Front/Booking/BookingLogin/components/BookingLoginErrors";

interface SellerForgottenPasswordRecoveryAccessFormProps {
    setAccessGranted: (boolean) => void;
    params: any;
    accessEmailRequested: string;
}

const SellerForgottenPasswordRecoveryAccessForm = (props: SellerForgottenPasswordRecoveryAccessFormProps) => {
    const {t} = useTranslation();
    const email_address = props.params.m_clk_id ? atob(props.params.m_clk_id) : null;
    const app_id = props.params.app_id ? atob(props.params.app_id) : "";
    const [recoveryError, setRecoveryError] = useState<boolean>(false);
    const [isSubmitting, setIsSubmitting] = useState<boolean>(false);
    const dispatch = useDispatch();
    const NewPasswordSchema = yup.object().shape({
        access_token: yup
            .string()
            .required(t("Access token is required field.")),
        new_password: yup
            .string()
            .required(t("New password is required field.")),
        confirm_password: yup
            .string()
            .required(t("Confirm password is required field."))
    });
    const defaultValues = {
        email: email_address,
        app_id: app_id,
        access_token: props.params.access_token || "",
        new_password: "",
        confirm_password: "",
    };
    const {
        register,
        handleSubmit,
        formState: {errors},
    } = useForm({
        resolver: yupResolver<any>(NewPasswordSchema),
        defaultValues: defaultValues,
    });

    const onSubmitPasswordReset = data => {
        if (isSubmitting) {
            return;
        }
        setIsSubmitting(true);

        dispatch(SellerAction.resetPassword(data.email, data.access_token, data.new_password, data.app_id))
            .then(r => {
                props.setAccessGranted(true);
                return r;
            })
            .catch(err => {
                toast.error(t("Error, please contact support."));
                setRecoveryError(true);
            })
            .finally(() => {
                setIsSubmitting(false);
            });
    };

    if (recoveryError) {
        return <SellerForgottenPasswordRecoveryError />;
    }

    return (
        <form
            className="w-full"
            id="wf-form-ForgottenPassword-Form"
            method="POST"
            name="wf-form-ForgottenPassword-Form"
            onSubmit={handleSubmit(onSubmitPasswordReset)}
        >
            <input type={"hidden"} {...register("email")} defaultValue={email_address ?? props.accessEmailRequested} />
            <input type={"hidden"} {...register("app_id")} defaultValue={app_id && app_id} />

            <div className={"my-2 text-center space-y-2"}>
                <label className="text-base font-bold text-gray-900">{t("Password recovery")}</label>
                <div className={"leading-4 px-3"}>
                    <p className={"text-gray-600"}>{t("Please enter access token delivered via e-mail and set-up a new password.")}</p>
                </div>
            </div>

            {props.params.access_token ? (
                <input type={"hidden"} {...register("access_token")} value={props.params.access_token} />
            ) : (
                <div className={"py-3"}>
                    <BookingLoginInput
                        className={"w-full pl-10"}
                        errors={errors}
                        label={t("Access token")}
                        name={"access_token"}
                        placeholder={"Enter access token"}
                        register={register}
                        required={true}
                        showIcon={true}
                        type={"text"}
                    />
                </div>
            )}

            <div className={"py-3 space-y-3"}>
                <BookingLoginInput
                    className={"w-full pl-10"}
                    errors={errors}
                    label={t("New password")}
                    name={"new_password"}
                    placeholder={"Enter new password"}
                    register={register}
                    required={true}
                    showIcon={true}
                    type={"text"}
                />
                <BookingLoginInput
                    className={"w-full pl-10"}
                    errors={errors}
                    label={t("Confirm password")}
                    name={"confirm_password"}
                    placeholder={"Confirm password"}
                    register={register}
                    required={true}
                    showIcon={true}
                    type={"text"}
                />
            </div>

            {errors && <BookingLoginErrors errors={errors} />}

            <div className={"sm:col-span-6 mt-4"}>
                <div className={"flex justify-center"}>
                    <ButtonSubmit
                        buttonType={"cta"}
                        className={"rounded-md"}
                        disabled={isSubmitting}
                        icon={"check"}
                        isLoading={isSubmitting}
                        label={t("Reset password")}
                        onClick={handleSubmit(onSubmitPasswordReset)}
                    />
                </div>
            </div>

            <div className="relative flex flex-col items-center mt-4">
                <Link
                    className={"flex space-x-1.5 items-center hover:text-black"}
                    to={"/booking/seller/login-with-password"}
                >
                    <ChevronLeftIcon className={"w-4 h-4 flex-none"} />
                    <span>{t("Back to main")}</span>
                </Link>
            </div>
        </form>
    );
};

export default SellerForgottenPasswordRecoveryAccessForm;