import React from "react";
import clsx from "clsx";

interface PackageTagProps {
    className?: string;
    label: string;
}
const PackageTag = (props: PackageTagProps) => {
    return (
        <span className={clsx(props.className ? props.className : "bg-primary-100", "px-2 py-1 text-gray-800 text-xs font-medium  rounded-full min-w-[90px]")}>
            {props.label}
        </span>
    );
};

export default PackageTag;
