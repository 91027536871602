import React from "react";
import DecrementButton from "pages/Front/Booking/components/GiftCardPackage/components/DecrementButton";
import {NumberInput} from "pages/Front/Booking/components/GiftCardPackage/components/NumberInput";
import {PersonType} from "types/enums/PersonType";
import {useTranslation} from "react-i18next";
import {ClipBlack} from "pages/Front/Booking/components/GiftCardPackage/components/ClipBlack";
import {ActivityType} from "types/enums/ActivityType";
import clsx from "clsx";

interface DriverCountProps {
    count: number;
    countChangeHandler: any;
    countChanged: any;
    personType: PersonType;
    price: string;
    onClick: any;
    activity: ActivityType;
}

const DriverCount = (props: DriverCountProps) => {
    let countsArray = [0, 1, 2, 3, 4, 5, 6, 7, 8, 9, 10, 11, 12, 13, 14, 15];
    if (props.activity === ActivityType.TheParkPlayground) {
        countsArray = [0, 1, 2, 3, 4, 5, 6, 7, 8, 9, 10];
    }
    const {t} = useTranslation();
    const isAddToCartDisabled = props.count === 0;

    return (
        <div className="DriverCount px-4 sm:px-6 pt-3 pb-6 z-20 grow flex justify-center flex-col items-center border border-solid border-gray-700 rounded-md">
            <h4 className={"py-2 text-base sm:text-lg font-medium"}>{t(props.personType === PersonType.Child ? "Children - Ticket" : "Adult - Ticket")}</h4>
            <span>{props.activity === ActivityType.Karting ? t("Rides") : t("Persons")}</span>
            <div className="flex items-center justify-center space-x-4">
                <DecrementButton
                    disabled={props.count === 0}
                    increment={-1}
                    size={"w-4 h-4 lg:w-5 lg:h-5 xl:w-6 xl:h-6"}
                    onClick={e => props.countChangeHandler(e, props.personType, -1)}
                />
                <NumberInput className={"w-20 lg:w-22 xl:w-24 h-4 lg:h-5 xl:h-6 box-content py-3 sm:py-4 text-xl lg:text-2xl xl:text-3xl flex"}>
                    <div className="absolute inset-0 overflow-hidden">
                        <div className="relative h-full countsArray flex flex-row pointer-events-none ">
                            {countsArray.map((number, index) => {
                                const position = (props.count - number) * -1;
                                return (
                                    <span
                                        key={index}
                                        className="flex items-center justify-center absolute inset-0 lg:w-22 xl:w-24 h-full transform-gpu duration-500 ease-in-out"
                                        style={{
                                            transform: `translate(${position * 237}px, 0px)`,
                                        }}
                                    >
                                        <ClipBlack>{number}</ClipBlack>
                                    </span>
                                );
                            })}
                        </div>
                    </div>
                    <input
                        className={"numbers"}
                        name={props.personType}
                        type="hidden"
                        value={props.count}
                        onChange={() => {
                            props.countChanged();
                        }}
                    />
                </NumberInput>
                <DecrementButton
                    disabled={props.count === 10}
                    increment={+1}
                    size={"w-4 h-4 lg:w-5 lg:h-5 xl:w-6 xl:h-6"}
                    onClick={e => props.countChangeHandler(e, props.personType, +1)}
                />
            </div>
            <div className={"py-2 font-medium text-sm sm:text-base "}>
                {props.price.length > 0 ? (props.activity === ActivityType.Karting ? t("Price per heat") : t("Price per person")) : null}{" "}
                {props.price.length > 0 ? props.price : "No price"}
            </div>
            <button
                className={clsx(
                    "bg-cta-800 text-contrast-800 w-full px-3 h-10 flex items-center justify-center hover:no-underline sm:text-xl rounded-md",
                    isAddToCartDisabled ? "bg-opacity-50 grayscale-[100%] cursor-not-allowed" : "cursor-pointer"
                )}
                type={"button"}
                onClick={props.onClick}
                disabled={isAddToCartDisabled}
            >
                {t("Add to cart")}
            </button>
        </div>
    );
};

export default DriverCount;
